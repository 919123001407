import React, { useEffect, useState } from "react";
import "../styles/signUp.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal, Button } from "react-bootstrap";
import submitIcon from "../Icons/submitIcon.svg";
import axios from "axios";

const SignUp = () => {
  //Initializing states for role dropdown
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [error, setError] = useState("");

  //Initializing state for successfully resistered message Dialog Box
  const [showRegisteredMsg, setShowRegisteredMsg] = useState(false);

  //function to select role dropdown
  const handleSelect = (eventKey) => {
    setSelectedRole(eventKey);
  };

  //function to toggle role dropdown
  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };

  const [formData, setFormData] = useState({
    organisationalemailid: "",
    roleid: 1,
    departmentname: "",
    fullname: "",
    requestreason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignUpModal = () => {
    setShowRegisteredMsg(false);
  };
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/roles"
        );
        if (response.status === 200) {
          const { body } = response.data;
          if (Array.isArray(body)) {
            setRoles(body);
          } else {
            console.error("Body is not an array:", body);
          }
        }
      } catch (error) {
        console.error("Error fetching Roles:", error);
      }
    };

    fetchRoles();
  }, []);

  const validateForm = () => {
    if (
      !formData.organisationalemailid ||
      !formData.departmentname ||
      !formData.fullname ||
      !formData.requestreason ||
      !selectedRole
    ) {
      setError("Please fill all the mandatory Fields.");
      return false;
    }
    setError("");
    return true;
  };

  const checkEmailInAD = async (emailid) => {
    console.log("checkEmailInAD", emailid);
    try {
      console.log("try", emailid);
      const response = await fetch(
        "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/emailvalidate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ emailid }),
        }
      );
      console.log("response email ad", response);
      const responseData = await response.json();
      if (response.status === 200) {
        console.log("responseData.body", responseData.body);
        return responseData.body.user_details; // Assuming the user data is in the first element of the value array
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      console.error("Error authenticating email:", error);
      return null;
    }
  };

  const handleSave = async () => {
    // Convert form data to JSON format
    const jsonData = JSON.stringify(formData);
    console.log("data saved");
    const emailid = formData.organisationalemailid;
    if (validateForm()) {
      const emailExists = await checkEmailInAD(emailid);
      console.log("email exists", emailExists);
      if (!emailExists) {
        setError("Email does not exist in Wipro AD.");
        return;
      }
      try {
        const response = await fetch(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/user",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: jsonData,
          }
        );

        if (response.ok) {
          // Handle success response
          console.log("Data saved successfully");
          setShowRegisteredMsg(true);
        } else {
          // Handle error response
          console.error("Failed to save data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  return (
    <div>
      <div
        className="credential_container"
        style={{ height: isDropdownOpen ? "815px" : "615px" }}
      >
        <div className="email_container">
          <label className="org_email_text" htmlFor="">
            Organizational Email ID<span className="astrick">*</span>
          </label>
          <br />
          <input
            className="org_email_field"
            type="email"
            name="organisationalemailid"
            placeholder="Enter your Organization email"
            onChange={handleChange}
            value={formData.organisationalemailid}
            autoComplete="off"
          />
          <br />
        </div>
        <div className="fullname_container">
          <label className="org_fullname" htmlFor="">
            Full Name<span className="astrick">*</span>
          </label>
          <input
            className="fullname_field"
            type="text"
            name="fullname"
            placeholder="Enter your name"
            onChange={handleChange}
            value={formData.fullname}
            autoComplete="off"
          />
        </div>
        <br />
        <div className="department_container">
          <label className="dep_fullname" htmlFor="">
            Department Name<span className="astrick">*</span>
          </label>
          <br />
          <input
            className="dep_name_field"
            type="text"
            name="departmentname"
            placeholder="Enter your Department Name"
            onChange={handleChange}
            value={formData.departmentname}
            autoComplete="off"
          />
        </div>
        <br />
        <div className="role_container">
          <label className="role" htmlFor="">
            Role<span className="astrick">*</span>
          </label>
          <br />

          {/* {Dropdown for Role Field} */}
          <Dropdown onSelect={handleSelect} onToggle={handleDropdownToggle}>
            <Dropdown.Toggle
              variant="success"
              className="dropdown_field_signup"
            >
              {selectedRole || "Choose Role"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown_menu_signup">
              {roles.map((role) => (
                <Dropdown.Item
                  key={role[0]}
                  eventKey={role[1]}
                  className="dropdown-item-signup"
                >
                  {role[1]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <br />
        <div
          className="request_container"
          style={{ marginTop: isDropdownOpen ? "200px" : null }}
        >
          <label className="request_reason" htmlFor="">
            Request Reason<span className="astrick">*</span>
          </label>
          <br />
          <textarea
            className="request_field"
            type="text"
            name="requestreason"
            placeholder="Provide the reason for request access"
            onChange={handleChange}
            value={formData.requestreason}
            autoComplete="off"
          />
          {error && (
            <span
              style={{
                color: "#FF3B30",
                fontFamily: "Noto Sans",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              {error}
            </span>
          )}
        </div>
        <div>
          <button className="signIn_btn" onClick={handleSave}>
            <p className="logIn_btn_text">Sign Up</p>
          </button>
        </div>
      </div>

      {/* Successfully Registered Dialog Box */}
      <Modal
        show={showRegisteredMsg}
        centered
        className="modalStyle-msg-signup"
      >
        <Modal.Body className="dialog-body">
          <div className="submit-icon1">
            <img src={submitIcon} alt="Ok" />
          </div>
          <div className="content">
            <h3 className="pswrd-sent-text">Registration Request Sent</h3>
            <p className="pswrd-sent-content">
              Your registration request has successfully submitted.
            </p>
            <p className="pswrd-sent-content">
              A verification code has been sent to your registered email ID.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="model_footer">
          <Button className="ok_btn" onClick={handleSignUpModal}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SignUp;
