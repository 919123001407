import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../Redux/userContext";
import LeftNavigation from "./leftNavigation";
import TopNavigation from "./topNavigation";
import "../styles/dashboard.css";
import HomePage from "./homePage";
import { useNavigate } from "react-router";
import RegulatoryStandards from "./regulatoryStandards";
import AdminConfiguration from "./adminConfiguration";
import { Inventory, InventoryTable } from "./inventory";
import UserManagement from "./userManagement";
import BusinessUnit from "./businessUnit";
import { CloudAccounts, CloudAccountTable } from "./cloudAccounts";
import { Integration, IntegrationAccountTable } from "./integration";
import { RiskAssessment, RiskAssessmentTable } from "./riskAssessment";
import {
  AlertTable,
  Alerts,
  ControlCheck,
  SuppressConfirmation,
} from "./alerts";

import axios from "axios";
import Settings from "./settings";
import {
  DashboardView,
  HoriBarChart,
  PieChart,
  VertBarChart,
  ApplicationWiseRegulatoryComplianceStatus,
  BuWiseCloudComplianceStatus,
  BuWiseRegulatoryCloudComplianceStatus,
  CloudAccoutsComplainceTable,
} from "./dashboardView";
import { Col, Row } from "react-bootstrap";
import Footer from "./footer";

const Dashboard = () => {
  const navigate = useNavigate();

  const { email } = useContext(UserContext);
  const [menuItemSelect, setMenuItemSelect] = useState("Home");
  const [activeTab, setActiveTab] = useState("User Management");
  const [edit, setEdit] = useState(false);
  const [editAWS, setEditAWS] = useState(false);
  const [editGCP, setEditGCP] = useState(false);
  const [editAZURE, setEditAZURE] = useState(false);
  const [uniqueFormData, setUniqueFormData] = useState([]);
  const [suppress, setSupress] = useState(1); //this state is using for Alert Screens
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isDropdownEnabled, setIsDropdownEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    cloudType: "",
    resourceType: "",
    assetType: "",
    region: "",
  });

  const [filterAlert, setFilterAlert] = useState({
    csp: "",
    alertSource: "",
  });

  // State for Risk Assessment search functionality
  const [filtersRisk, setFiltersRisk] = useState({
    complianceRisk: "",
    technicalRisk: "",
    severity: "",
    controlCategory: "",
    regulatoryStandard: "",
  });

  // Function to update filter values of inventory
  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
    if (field === "name") {
      setIsDropdownEnabled(true);
    }
  };

  // Function to update filter values of Alert
  const handleFilterAlertChange = (field, value) => {
    setFilterAlert((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
    // need to change when API will integrate
    if (field === "csp") {
      setIsDropdownEnabled(true);
    }
  };

  // Function to update filter values of risk assessment
  const handleRiskAssessmentFilterChange = (field, value) => {
    setFiltersRisk((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
    if (field === "regulatoryStandard") {
      setIsDropdownEnabled(true);
    }
  };

  // Function to update search values of inventory
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  // Function to reset search values of inventory
  const handleReset = () => {
    setFilters({
      cloudType: "",
      resourceType: "",
      assetType: "",
      region: "",
    });
    setSearchTerm("");
    setIsDropdownEnabled(false);
  };

  // Function to reset search values of Alert
  const handleResetAlert = () => {
    setFilterAlert({
      csp: "",
      alertSource: "",
    });
    setSearchTerm("");
    setIsDropdownEnabled(false);
  };

  // Function to reset search values of risk assessment
  const handleResetRiskAssessment = () => {
    setFiltersRisk({
      complianceRisk: "",
      technicalRisk: "",
      severity: "",
      controlCategory: "",
      regulatoryStandard: "",
    });
    setSearchTerm("");
    setIsDropdownEnabled(false);
  };

  useEffect(() => {
    const storedMenu = localStorage.getItem("menuItemSelect");
    if (storedMenu) {
      setMenuItemSelect(storedMenu);
    }
  }, []);

  const handleMenuItemSelect = (item) => {
    setMenuItemSelect(item);
    console.log("menu item selected");
    console.log("Selected Tab", menuItemSelect);
    localStorage.setItem("menuItemSelect", item);
    if (item === "Home") {
      navigate("/dashboard/home");
    } else if (item === "Regulatory Standards") {
      navigate("/dashboard/regulatoryStandards");
    } else if (item === "Admin Configuration") {
      navigate("/dashboard/adminConfiguration");
    } else if (item === "Inventory") {
      navigate("/dashboard/inventory");
    } else if (item === "Alerts") {
      navigate("/dashboard/alerts");
    } else if (item === "Risk Assessment") {
      navigate("/dashboard/riskAssessment");
    } else if (item === "Settings") {
      navigate("/dashboard/settings");
    } else if (item === "dashboardView") {
      navigate("/dashboard/dashboardView");
    } else navigate("/dashboard/home");
  };

  const renderContent = () => {
    switch (menuItemSelect) {
      case "User Management":
        return <HomePage />;
      case "Regulatory Standards":
        return <RegulatoryStandards />;
      case "Admin Configuration":
        return (
          <AdminConfiguration
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        );
      case "Inventory":
        return (
          <Inventory
            isDropdownEnabled={isDropdownEnabled}
            filters={filters}
            onFilterChange={handleFilterChange}
            onSearch={handleSearch}
            onReset={handleReset}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        );
      case "Alerts":
        return (
          <Alerts
            isDropdownEnabled={isDropdownEnabled}
            filterAlert={filterAlert}
            handleFilterAlertChange={handleFilterAlertChange}
            suppress={suppress}
            setSupress={setSupress}
            handleResetAlert={handleResetAlert}
          />
        );
      case "Risk Assessment":
        return (
          <RiskAssessment
            filtersRisk={filtersRisk}
            handleResetRiskAssessment={handleResetRiskAssessment}
            handleRiskAssessmentFilterChange={handleRiskAssessmentFilterChange}
            isDropdownEnabled={isDropdownEnabled}
          />
        );
      case "dashboardView":
        return (
          <DashboardView
            show={show}
            setShow={setShow}
            checkedItems={checkedItems}
            handleCheckboxChange={handleCheckboxChange}
            handleUpdateClick={handleUpdateClick}
          />
        );
      case "Settings":
        return <Settings />;
      default:
        return <HomePage />;
    }
  };

  const renderAdminTab = () => {
    switch (activeTab) {
      case "User Management":
        return <UserManagement />;
      case "Business Units":
        return <BusinessUnit />;
      case "Cloud Accounts":
        return (
          <CloudAccounts
            editAWS={editAWS}
            setEditAWS={setEditAWS}
            editAZURE={editAZURE}
            setEditAZURE={setEditAZURE}
            editGCP={editGCP}
            setEditGCP={setEditGCP}
            uniqueFormData={uniqueFormData}
            setUniqueFormData={setUniqueFormData}
            refreshTableData={fetchData}
            tableData={tableData}
            setTableData={setTableData}
            onSaveSuccess={handleSaveSuccess}
            selectedAccount={selectedAccount}
          />
        );
      case "Integration":
        return <Integration edit={edit} setEdit={setEdit} />;
      default:
        return <UserManagement />;
    }
  };

  const [tableData, setTableData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/cloudaccounts"
      );
      setTableData(response.data.body);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to be called after saving or editing
  const handleSaveSuccess = () => {
    setSelectedAccount(null); // Clear the form after saving/editing
  };

  // Function to handle when the Edit button is clicked
  const handleEdit = (row) => {
    setSelectedAccount(row); // Set the account to be edited
  };

  // Dummy data for dashboardView UI
  const dummyData = {
    CloutAccoutsComplainceData: [
      {
        CSP: "AWS",
        "Cloud Account": "123456789012",
        "ISO 27001: 2022": "100%",
        "PCI DSS 4.0": "91%",
        "NIST 800-53 R5": "96%",
        HIPAA: "87%",
        GDPR: "100%",
      },
      {
        CSP: "Azure",
        "Cloud Account": "1bc3aca-5016-4db0-a6bc-1111fccdf72b",
        "ISO 27001: 2022": "90%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "97%",
        HIPAA: "88%",
        GDPR: "78%",
      },
      {
        CSP: "GCP",
        "Cloud Account": "CCS_Soteria-project",
        "ISO 27001: 2022": "87%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "91%",
        HIPAA: "92%",
        GDPR: "93%",
      },
      {
        CSP: "GCP",
        "Cloud Account": "CKM_project-Sonia",
        "ISO 27001: 2022": "100%",
        "PCI DSS 4.0": "65%",
        "NIST 800-53 R5": "89%",
        HIPAA: "100%",
        GDPR: "88%",
      },
      {
        CSP: "Azure",
        "Cloud Account": "2bc3aca-5016-4db0-a6bc-1111fccdfc78",
        "ISO 27001: 2022": "70%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "77%",
        HIPAA: "72%",
        GDPR: "74%",
      },
      {
        CSP: "AWS",
        "Cloud Account": "234567890121",
        "ISO 27001: 2022": "99%",
        "PCI DSS 4.0": "98%",
        "NIST 800-53 R5": "100%",
        HIPAA: "66%",
        GDPR: "100%",
      },
    ],
    BuWiseRegulatoryCloudComplianceData: [
      {
        "BU Name": "Americas1",
        "ISO 27001: 2022": "100%",
        "PCI DSS 4.0": "91%",
        "NIST 800-53 R5": "96%",
        HIPAA: "87%",
        GDPR: "100%",
      },
      {
        "BU Name": "Americas2",
        "ISO 27001: 2022": "90%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "97%",
        HIPAA: "88%",
        GDPR: "78%",
      },
      {
        "BU Name": "APMEA",
        "ISO 27001: 2022": "87%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "91%",
        HIPAA: "92%",
        GDPR: "93%",
      },
      {
        "BU Name": "Europe",
        "ISO 27001: 2022": "100%",
        "PCI DSS 4.0": "65%",
        "NIST 800-53 R5": "89%",
        HIPAA: "100%",
        GDPR: "88%",
      },
      {
        "BU Name": "WC",
        "ISO 27001: 2022": "70%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "77%",
        HIPAA: "72%",
        GDPR: "74%",
      },
      {
        "BU Name": "WEE",
        "ISO 27001: 2022": "99%",
        "PCI DSS 4.0": "98%",
        "NIST 800-53 R5": "100%",
        HIPAA: "66%",
        GDPR: "100%",
      },
    ],
    BuWiseCloudComplianceData: [
      {
        "BU Name": "Americas1",
        CSP: "AWS",
        "Cloud Account": "123456789012",
        "ISO 27001: 2022": "100%",
        "PCI DSS 4.0": "91%",
        "NIST 800-53 R5": "96%",
        HIPAA: "87%",
        GDPR: "100%",
      },
      {
        "BU Name": "Americas2",
        CSP: "Azure",
        "Cloud Account": "1bc3aca-5016-4db0-a6bc-1111fccdf72b",
        "ISO 27001: 2022": "90%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "97%",
        HIPAA: "88%",
        GDPR: "78%",
      },
      {
        "BU Name": "APMEA",
        CSP: "GCP",
        "Cloud Account": "CCS_Soteria-project",
        "ISO 27001: 2022": "87%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "91%",
        HIPAA: "92%",
        GDPR: "93%",
      },
      {
        "BU Name": "Europe",
        CSP: "GCP",
        "Cloud Account": "CKM_project-Sonia",
        "ISO 27001: 2022": "100%",
        "PCI DSS 4.0": "65%",
        "NIST 800-53 R5": "89%",
        HIPAA: "100%",
        GDPR: "88%",
      },
      {
        "BU Name": "WC",
        CSP: "Azure",
        "Cloud Account": "2bc3aca-5016-4db0-a6bc-1111fccdfc78",
        "ISO 27001: 2022": "70%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "77%",
        HIPAA: "72%",
        GDPR: "74%",
      },
      {
        "BU Name": "WEE",
        CSP: "AWS",
        "Cloud Account": "234567890121",
        "ISO 27001: 2022": "99%",
        "PCI DSS 4.0": "98%",
        "NIST 800-53 R5": "100%",
        HIPAA: "66%",
        GDPR: "100%",
      },
    ],
    ApplicationWiseRegulatoryComplianceData: [
      {
        "Application Name": "SkyCanvas",
        "BU Name": "CRS",
        "ISO 27001: 2022": "87%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "100%",
        HIPAA: "91%",
        GDPR: "96%",
      },
      {
        "Application Name": "CodeStream",
        "BU Name": "DAAI",
        "ISO 27001: 2022": "88%",
        "PCI DSS 4.0": "78%",
        "NIST 800-53 R5": "90%",
        HIPAA: "100%",
        GDPR: "97%",
      },
      {
        "Application Name": "GreenThumb",
        "BU Name": "DIGITAL",
        "ISO 27001: 2022": "92%",
        "PCI DSS 4.0": "93%",
        "NIST 800-53 R5": "87%",
        HIPAA: "100%",
        GDPR: "91%",
      },
      {
        "Application Name": "MindGym",
        "BU Name": "FSC",
        "ISO 27001: 2022": "100%",
        "PCI DSS 4.0": "88%",
        "NIST 800-53 R5": "100%",
        HIPAA: "65%",
        GDPR: "89%",
      },
      {
        "Application Name": "ZenSpace",
        "BU Name": "CIS",
        "ISO 27001: 2022": "72%",
        "PCI DSS 4.0": "74%",
        "NIST 800-53 R5": "70%",
        HIPAA: "100%",
        GDPR: "77%",
      },
      {
        "Application Name": "TuneCraft",
        "BU Name": "CAM",
        "ISO 27001: 2022": "66%",
        "PCI DSS 4.0": "100%",
        "NIST 800-53 R5": "99%",
        HIPAA: "98%",
        GDPR: "100%",
      },
    ],
  };

  // Dashboard view hide and seek:

  // State to manage checkboxes
  const [checkedItems, setCheckedItems] = useState({
    cloudAccountDetails: true,
    overallCloudComplianceStatus: true,
    comprehensiveOverview: true,
    cloudAccountRegulatoryStatus: true,
    buWiseRegulatoryStatus: true,
    buWiseCloudStatus: true,
    applicationWiseRegulatoryStatus: true,
  });

  // State to manage visibility of components
  const [visibleItems, setVisibleItems] = useState({
    cloudAccountDetails: true,
    overallCloudComplianceStatus: true,
    comprehensiveOverview: true,
    cloudAccountRegulatoryStatus: true,
    buWiseRegulatoryStatus: true,
    buWiseCloudStatus: true,
    applicationWiseRegulatoryStatus: true,
  });
  const [show, setShow] = useState(false);
  // Dashboard view hide and seek:

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  // Dashboard view hide and seek:

  const handleUpdateClick = () => {
    setVisibleItems(checkedItems);
    setShow(false); // Hide the overlay after updating
  };

  return (
    <div className="dashboard_container">
      <TopNavigation />
      <div className="main-section">
        <LeftNavigation
          onMenuClick={handleMenuItemSelect}
          menuItemSelect={menuItemSelect}
        />
        <div className="content-area">
          <div className="content-box">{renderContent()}</div>
          {menuItemSelect === "Admin Configuration" ? (
            <div className="content-box">{renderAdminTab()}</div>
          ) : (
            ""
          )}
          {menuItemSelect === "Inventory" ? (
            <div className="content-box">
              <InventoryTable filters={filters} searchTerm={searchTerm} />
            </div>
          ) : (
            ""
          )}
          {menuItemSelect === "Alerts" ? (
            <div className="content-box">
              {suppress === 1 ? (
                <AlertTable
                  suppress={suppress}
                  setSupress={setSupress}
                  filterAlert={filterAlert}
                  searchTerm={searchTerm}
                />
              ) : suppress === 2 ? (
                <ControlCheck suppress={suppress} setSupress={setSupress} />
              ) : (
                <SuppressConfirmation
                  suppress={suppress}
                  setSupress={setSupress}
                />
              )}
            </div>
          ) : (
            ""
          )}
          {menuItemSelect === "Risk Assessment" ? (
            <div className="content-box">
              {
                <RiskAssessmentTable
                  filtersRisk={filtersRisk}
                  searchTerm={searchTerm}
                />
              }
            </div>
          ) : (
            ""
          )}

          {menuItemSelect === "dashboardView" ? (
            <>
              <div className="context_box_dashboard">
                <Row>
                  <Col md={4}>
                    {visibleItems.cloudAccountDetails && <PieChart />}
                  </Col>
                  <Col md={4}>
                    {visibleItems.overallCloudComplianceStatus && (
                      <HoriBarChart />
                    )}
                  </Col>
                  <Col md={4}>
                    {visibleItems.comprehensiveOverview && <VertBarChart />}
                  </Col>
                </Row>
              </div>
              {visibleItems.cloudAccountRegulatoryStatus && (
                <div className="content-box">
                  {<CloudAccoutsComplainceTable dummyData={dummyData} />}
                </div>
              )}
              {visibleItems.buWiseRegulatoryStatus && (
                <div className="content-box">
                  {
                    <BuWiseRegulatoryCloudComplianceStatus
                      dummyData={dummyData}
                    />
                  }
                </div>
              )}
              {visibleItems.buWiseCloudStatus && (
                <div className="content-box">
                  {<BuWiseCloudComplianceStatus dummyData={dummyData} />}
                </div>
              )}
              {visibleItems.applicationWiseRegulatoryStatus && (
                <div className="content-box">
                  {
                    <ApplicationWiseRegulatoryComplianceStatus
                      dummyData={dummyData}
                    />
                  }
                </div>
              )}
            </>
          ) : (
            ""
          )}

          {editAWS || editAZURE || editGCP ? (
            ""
          ) : activeTab === "Cloud Accounts" &&
            menuItemSelect === "Admin Configuration" ? (
            <div className="content-box">
              <CloudAccountTable
                loading={loading}
                editAWS={editAWS}
                setEditAWS={setEditAWS}
                editAZURE={editAZURE}
                setEditAZURE={setEditAZURE}
                editGCP={editGCP}
                setEditGCP={setEditGCP}
                uniqueFormData={uniqueFormData}
                setUniqueFormData={setUniqueFormData}
                tableData={tableData}
                setTableData={setTableData}
                onEdit={handleEdit}
                refreshTableData={fetchData}
              />
            </div>
          ) : (
            ""
          )}
          {edit ? (
            ""
          ) : activeTab === "Integration" &&
            menuItemSelect === "Admin Configuration" ? (
            <div className="content-box">
              <IntegrationAccountTable edit={edit} setEdit={setEdit} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Dashboard;
