import React, { useEffect, useState } from "react";
import {
  Button,
  OverlayTrigger,
  Table,
  Modal,
  Dropdown,
  Spinner,
  Popover,
} from "react-bootstrap";
import searchIcon from "../Icons/searchIcon.svg";
import "../styles/inventory.css";
import tooltip from "../Icons/tooltip.svg";
import submitIcon from "../Icons/submitIcon.svg";
import axios from "axios";

export const Inventory = ({
  filters,
  onFilterChange,
  onSearch,
  onReset,
  isDropdownEnabled,
  setSearchTerm,
  searchTerm,
}) => {
  // const [searchTerm, setSearchTerm] = useState("");
  const [cloudTypes, setCloudTypes] = useState([]);

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  useEffect(() => {
    const fetchCloudTypes = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/cloudtypemaster"
        );
        if (response.status === 200) {
          const { body } = response.data;
          if (Array.isArray(body)) {
            setCloudTypes(body);
          } else {
            console.error("Body is not an array:", body);
          }
        }
      } catch (error) {
        console.error("Error fetching Cloud Types:", error);
      }
    };

    fetchCloudTypes();
  }, []);

  // const [selectedOptionCT, setSelectedOptionCT] = useState('');

  // const handleSelectCT = (eventKey) => {
  //   setSelectedOptionCT(eventKey);
  // };

  // const handleSearch = () => {
  //   if (searchTerm) {
  //     const filtered = data.filter((item) =>
  //       item.regulatory_standard_name
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase())
  //     );
  //     setFilteredData(filtered);
  //   } else {
  //     setFilteredData(data); // Reset to full data if search term is empty
  //   }
  // };

  // const handleReset = () => {
  //   setSearchTerm("");
  //   setFilteredData(data);
  // };
  return (
    <div>
      <div className="title-inventory">Cloud Inventory</div>
      <div className="filter mt-3">
        <div className="field me-4">
          <label htmlFor="" className="cloud-type">
            Cloud Type
          </label>
          <Dropdown onSelect={(eventKey) => onFilterChange("name", eventKey)}>
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown_field_cloud_type"
            >
              <span
                style={{
                  color: "#1A202C",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {filters.name || "Choose Cloud Type"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown_menu_cloud_type">
              {cloudTypes
                .sort((a, b) => a[1].localeCompare(b[1]))
                .map((cloud) => (
                  <Dropdown.Item
                    key={cloud[0]}
                    eventKey={cloud[1]}
                    className="dropdown_item_cloud_type"
                  >
                    {cloud[1]}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="field me-4">
          <label htmlFor="" className="cloud-type">
            Resource Type
          </label>
          <Dropdown
            onSelect={(eventKey) => onFilterChange("resourcetype", eventKey)}
          >
            <Dropdown.Toggle
              className="dropdown_field_resource_type"
              disabled={!isDropdownEnabled}
              variant="secondary"
            >
              <span
                style={{
                  color: "#1A202C",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {" "}
                {filters.resourcetype || "Choose Resource Type"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown_menu_resource_type">
              <Dropdown.Item
                href="#"
                className="dropdown_item_resource_type"
                eventKey="s3"
              >
                s3
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="dropdown_item_resource_type"
                eventKey="rds"
              >
                rds
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="dropdown_item_resource_type"
                eventKey="CloudTrail"
              >
                CloudTrail
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="field me-4">
          <label htmlFor="" className="cloud-type">
            Asset Type
          </label>
          <Dropdown
            onSelect={(eventKey) => onFilterChange("assettype", eventKey)}
          >
            <Dropdown.Toggle
              className="dropdown_field_asset_type"
              disabled={!isDropdownEnabled}
              variant="secondary"
            >
              <span
                style={{
                  color: "#1A202C",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {" "}
                {filters.assettype || " Choose Asset Type"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown_menu_asset_type">
              <Dropdown.Item
                href="#"
                className="dropdown_item_asset_type"
                eventKey="s3 bucket"
              >
                s3 bucket
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="dropdown_item_asset_type"
                eventKey="rdssnapshot"
              >
                rdssnapshot
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="dropdown_item_asset_type"
                eventKey="Trail"
              >
                Trail
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="field me-4">
          <label htmlFor="" className="cloud-type">
            Region
          </label>
          <Dropdown onSelect={(eventKey) => onFilterChange("region", eventKey)}>
            <Dropdown.Toggle
              variant="secondary"
              id="filter4"
              className="dropdown_field_region"
              disabled={!isDropdownEnabled}
            >
              <span
                style={{
                  color: "#1A202C",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {" "}
                {filters.region || " Choose Region"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown_menu_region">
              <Dropdown.Item
                href="#"
                className="dropdown_item_region"
                eventKey="ap-southeast-1"
              >
                ap-southeast-1
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="dropdown_item_region"
                eventKey="ap-south-1"
              >
                ap-south-1
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="top-panel-inventory">
        <div className="search-bar-inventory">
          <img
            src={searchIcon}
            alt="Seach Icon"
            className="search-icon-inventory"
          />
          <input
            type="text"
            placeholder="Search Assets"
            className="search-input-inventory"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button className="search-button-inventory" onClick={handleSearch}>
            Search
          </Button>
          <span
            className="reset-text-inventory"
            style={{ cursor: "pointer" }}
            onClick={onReset}
          >
            Reset
          </span>
        </div>
      </div>
    </div>
  );
};

export const InventoryTable = ({ filters, searchTerm }) => {
  const [selectedOptionBU, setSelectedOptionBU] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [businessUnits, setBusinessUnits] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [inventory, setInventory] = useState([]);

  const handleSelect = (key, index) => {
    setSelectedOptionBU((prevState) => ({
      ...prevState,
      [index]: key,
    }));
  };

  const handleSave = () => {
    console.log("saved clicked");
    setIsSaved(true);
    setModalVisible(true);
  };
  const handleOk = () => {
    setModalVisible(false);
    if (!isSaved) {
      setCheckedItems({});
    }
    setIsSaved(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/inventory"
        );
        console.log("inventory response data", response.data);
        console.log("inventory response data body", response.data.body);
        console.log(
          "inventory response data body rows",
          response.data.body.Rows
        );
        // setInventory(response.data.body.Rows);
        const inventory = response.data.body.Rows;
        console.log("inventory", inventory);
        //  Filter the data based on the filter values
        const filtered = inventory.filter(
          (item) =>
            (!filters.name || item.cloudtype === filters.name) &&
            (!filters.resourcetype ||
              item.resourcetype === filters.resourcetype) &&
            (!filters.assettype || item.assettype === filters.assettype) &&
            (!filters.region || item.region === filters.region) &&
            (!searchTerm ||
              item.name.toLowerCase().includes(searchTerm.toLowerCase()))
        );

        setFilteredData(filtered);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [filters, searchTerm]);

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/businessunit"
        );
        if (response.status === 200) {
          console.log("inventory", response.data);
          const { body } = response.data; // Extract the body field from the response
          if (Array.isArray(body)) {
            setBusinessUnits(body); // Set the body array as the business units
          } else {
            console.error("Body is not an array:", body);
          }
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };

    fetchBusinessUnits();
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prev) => ({ ...prev, [name]: checked }));
  };
  return (
    <div
      className="container"
      style={{ maxHeight: "500px", overflow: "auto", maxWidth: "100%" }}
    >
      <div>
        <Button
          className="save-btn-regulatory"
          style={{ float: "right", marginBottom: "10px" }}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
      <Table
        className="custom-table-integration"
        style={{ minWidth: "800px", width: "100%" }}
      >
        <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
          <tr>
            {/* <th>Select</th> */}
            <th>Asset ID</th>
            <th>Resource</th>
            <th>Asset Type</th>
            <th>
              Cloud ID
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover className="custom_tooltip">
                    Account ID/Subscription ID/Project ID
                  </Popover>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <img
                    src={tooltip}
                    alt=""
                    ref={ref}
                    roundedCircle
                    {...triggerHandler}
                    className="d-inline-flex align-items-center mx-1"
                  />
                )}
              </OverlayTrigger>
            </th>
            <th>Region</th>
            <th>Business Unit</th>
            <th>App Name</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "70%",
                marginLeft: "500px",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            filteredData.map((asset, index) => (
              <tr key={index}>
                {/* <td>
                  <input
                    type="checkbox"
                    name="asset"
                    checked={checkedItems[asset.name]}
                    onChange={handleCheckboxChange}
                    className="checkbox"
                  />
                </td> */}
                <td>{asset.name}</td>
                <td>{asset.resourcetype}</td>
                <td>{asset.assettype}</td>
                <td>{asset.accountid}</td>
                <td>{asset.region}</td>
                <td>
                  <Dropdown onSelect={(key) => handleSelect(key, index)}>
                    <Dropdown.Toggle className="dropdown_field_business_unit">
                      {selectedOptionBU[index] || "Select Business Unit"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown_menu_business_unit">
                      {businessUnits
                        .sort((a, b) => a[1].localeCompare(b[1]))
                        .map((unit) => (
                          <Dropdown.Item
                            key={unit[0]}
                            eventKey={unit[1]}
                            className="dropdown_item_business_unit"
                          >
                            {unit[1]}{" "}
                            {/* Displays Business Unit Name and Description */}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td>
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Key In Application Name"
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Modal show={modalVisible} centered className="modal-createbu">
        <Modal.Body className="createbu-dialog-body">
          <div className="submit-icon">
            <img src={submitIcon} alt="Ok" />
          </div>
          <h3 className="user-createbu-text">Inventory Saved</h3>
          <p className="success-createbu-content">
            Inventory has been successfully saved.
          </p>
        </Modal.Body>
        <Modal.Footer className="createbu-model-footer">
          <Button className="ok_button" onClick={handleOk}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
