import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../styles/adminCong.css";
import UserManagement from "./userManagement";
import BusinessUnit from "./businessUnit";

const AdminConfiguration = ({ activeTab, setActiveTab }) => {
  return (
    <div>
      {/* First content area for title and tabs */}
      <div className="box title-tabs-box">
        <div className="title mb-2">Administrator Configuration</div>
        <div className="button-tabs mb-3">
          <Button
            variant={
              activeTab === "User Management" ? "primary" : "outline-primary"
            }
            onClick={() => setActiveTab("User Management")}
            style={{
              color: activeTab === "User Management" ? "white" : "",
              backgroundColor: activeTab === "User Management" ? "#2787E4" : "",
              fontWeight: activeTab === "User Management" ? "600" : "",
              border: activeTab === "User Management" ? "#2787E4" : "",
              height: "44px",
            }}
            className="tab-button"
          >
            User Management
          </Button>
          <Button
            variant={
              activeTab === "Business Units" ? "primary" : "outline-primary"
            }
            onClick={() => setActiveTab("Business Units")}
            style={{
              color: activeTab === "Business Units" ? "white" : "",
              backgroundColor: activeTab === "Business Units" ? "#2787E4" : "",
              fontWeight: activeTab === "Business Units" ? "600" : "",
              border: activeTab === "Business Units" ? "#2787E4" : "",
            }}
            className="tab-button"
          >
            Business Units
          </Button>
          <Button
            variant={
              activeTab === "Cloud Accounts" ? "primary" : "outline-primary"
            }
            onClick={() => setActiveTab("Cloud Accounts")}
            style={{
              color: activeTab === "Cloud Accounts" ? "white" : "",
              backgroundColor: activeTab === "Cloud Accounts" ? "#2787E4" : "",
              fontWeight: activeTab === "Cloud Accounts" ? "600" : "",
              border: activeTab === "Cloud Accounts" ? "#2787E4" : "",
            }}
            className="tab-button"
          >
            Cloud Accounts
          </Button>
          <Button
            variant={
              activeTab === "Integration" ? "primary" : "outline-primary"
            }
            onClick={() => setActiveTab("Integration")}
            style={{
              color: activeTab === "Integration" ? "white" : "",
              backgroundColor: activeTab === "Integration" ? "#2787E4" : "",
              fontWeight: activeTab === "Integration" ? "600" : "",
              border: activeTab === "Integration" ? "#2787E4" : "",
            }}
            className="tab-button"
          >
            Integration
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminConfiguration;
