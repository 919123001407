import React, { useState } from "react";
import "../styles/landingPage.css";
import RSimage from "../Images/RSimage.svg";
import WiproLogo from "../Images/WiproLogo.svg";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import SignIn from "./signIn";
import SignUp from "./signUp";
const LandingPage = () => {
  //Active and Unactive button
  const [activeButton, setActiveButton] = useState("login");

  //toggle button for login/signup
  const handleBtnToggle = (buttonType) => {
    setActiveButton(buttonType);
  };

  return (
    // handling responsive
    <div>
      <Row>
        <Col md={7} className="left">
          <div className="left_image_container">
            <img src={WiproLogo} alt="" />
            <p className="CCS_text">Cloud Compliance Shield</p>
          </div>
          {activeButton === "login" ? (
            <div className="text_container">
              <p className="get_started_now">Get Started Now </p>
              <p className="credential_to_access">
                Enter your credentials to access your account
              </p>
            </div>
          ) : (
            ""
          )}

          <div
            className={
              activeButton === "login"
                ? "box_Container"
                : "box_Container_signUp"
            }
          >
            <div className="button_container">
              <button
                //   active login button css

                className={`logIn_button ${
                  activeButton === "login" ? "active" : ""
                }`}
                onClick={() => handleBtnToggle("login")}
              >
                <p className="login_text">Log In</p>
              </button>
              <button
                //   active signup button css
                className={`signUp_btn ${
                  activeButton === "signup" ? "active" : ""
                }`}
                onClick={() => handleBtnToggle("signup")}
              >
                <p className="signUp_text">Sign Up</p>
              </button>
            </div>
            {/* Active signin/signup component */}
            {activeButton === "login" ? <SignIn /> : <SignUp />}
          </div>
        </Col>
        <Col className="right text-center" md={5}>
          <span className="right_box_text ">
            AI-Driven Insights for Seamless Compliance <br /> And Security
            Across Multi-Cloud Ecosystems.
          </span>
          <div className="right_img my-5">
            <img className="rs_1 " src={RSimage} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LandingPage;
