import React from "react";
import { Route, Routes, Navigate } from "react-router";
import LandingPage from "../Views/landingPage";
import LandingPageVC from "../Views/landingPageVC";
import LandingPageSP from "../Views/landingPageSP";
import SignUp from "../Views/signUp";
import SignIn from "../Views/signIn";
import Dashboard from "../Views/dashboard";
import HomePage from "../Views/homePage";
import RegulatoryStandards from "../Views/regulatoryStandards";
import AdminConfiguration from "../Views/adminConfiguration";
import { Inventory, InventoryTable } from "../Views/inventory";
import UserManagement from "../Views/userManagement";
import { RiskAssessment } from "../Views/riskAssessment";
import { Alerts } from "../Views/alerts";
import Settings from "../Views/settings";
import { DashboardView } from "../Views/dashboardView";
// import { AuthProvider } from "./authContext";
// import ProtectedRoute from "./protectedRoute";
const RouterMain = () => {
  return (
    <Routes>
      {/* setting up router for navigation between different routes */}

      <Route path="/" element={<LandingPage />} />
      <Route path="/verifycode" element={<LandingPageVC />} />
      <Route path="/setpassword" element={<LandingPageSP />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/signIn" element={<SignIn />} />
      <Route path="/dashboard" element={<Dashboard />}>
        <Route path="home" element={<HomePage />} />
        <Route path="regulatoryStandards" element={<RegulatoryStandards />} />
        <Route path="adminConfiguration" element={<AdminConfiguration />} />
        <Route path="userManagement" element={<UserManagement />} />
        <Route path="inventory" element={<Inventory />} />
        <Route path="inventory" element={<InventoryTable />} />
        <Route path="riskAssessment" element={<RiskAssessment />} />
        <Route path="alerts" element={<Alerts />} />
        <Route path="settings" element={<Settings />} />
        <Route path="dashboardView" element={<DashboardView />} />
      </Route>
      <Route path="/dashboard" element={<Navigate to="/dashboard/home" />} />
    </Routes>
    // <AuthProvider>
    //   <Routes>
    //     <Route path="/" element={<LandingPage />} />
    //     <Route path="/verifycode" element={<LandingPageVC />} />
    //     <Route path="/setpassword" element={<LandingPageSP />} />
    //     <Route
    //       path="/dashboard"
    //       element={<ProtectedRoute element={<Dashboard />} />}
    //     >
    //       <Route
    //         path="home"
    //         element={<ProtectedRoute element={<HomePage />} />}
    //       />
    //       <Route
    //         path="regulatoryStandards"
    //         element={<ProtectedRoute element={<RegulatoryStandards />} />}
    //       />
    //       <Route
    //         path="adminConfiguration"
    //         element={<ProtectedRoute element={<AdminConfiguration />} />}
    //       />
    //       <Route
    //         path="userManagement"
    //         element={<ProtectedRoute element={<UserManagement />} />}
    //       />
    //       <Route
    //         path="inventory"
    //         element={<ProtectedRoute element={<Inventory />} />}
    //       />
    //       <Route
    //         path="inventoryTable"
    //         element={<ProtectedRoute element={<InventoryTable />} />}
    //       />
    //       <Route
    //         path="riskAssessment"
    //         element={<ProtectedRoute element={<RiskAssessment />} />}
    //       />
    //       <Route
    //         path="alerts"
    //         element={<ProtectedRoute element={<Alerts />} />}
    //       />
    //       <Route
    //         path="settings"
    //         element={<ProtectedRoute element={<Settings />} />}
    //       />
    //       <Route
    //         path="dashboardView"
    //         element={<ProtectedRoute element={<DashboardView />} />}
    //       />
    //     </Route>
    //     <Route path="/dashboard" element={<Navigate to="/dashboard/home" />} />
    //   </Routes>
    // </AuthProvider>
  );
};

export default RouterMain;
