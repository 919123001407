import React, { useState } from "react";
import "../styles/landingPageSP.css";
import RSimage from "../Images/RSimage.svg";
import WiproLogo from "../Images/WiproLogo.svg";
import { Row, Col, Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import submitIcon from "../Icons/submitIcon.svg";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import axios from "axios";

const LandingPageSP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
    } else if (password !== confirmPassword) {
      setError("Password doesn’t match.");
    } else {
      setError("");
      console.log(email, "email");
      console.log(password, "password");
      // setShowConfirmMsg(true);
      try {
        const response = await axios.post(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/user/setpassword",
          {
            organisationalemailid: email,
            new_password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response data", response.data);
        if (response.data.statusCode === 200) {
          setShowConfirmMsg(true);
        }
      } catch (error) {
        console.error("Error logging in:", error);
      }
    }
  };
  const handleOk = () => {
    setShowConfirmMsg(false);
    navigate("/");
  };

  return (
    <div>
      <Row>
        <Col md={7} className="left">
          <div className="left_image_container">
            <img src={WiproLogo} alt="" />
            <p className="CCS_text">Cloud Compliance Shield</p>
          </div>
          <div className="pass_container">
            <p className="set_new_password">Set New Password</p>
          </div>

          <div className="box_Container_sp">
            <div className="password_container_sp">
              <div className="new_password_container">
                <label className="new_password_text" htmlFor="">
                  New Password
                  <span className="astrick">*</span>
                </label>
                <br />
                <input
                  className="password_field"
                  type="password"
                  name=""
                  id=""
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="confirm_password_container">
                <label className="confirm_password_text" htmlFor="">
                  Confirm New Password
                  <span className="astrick">*</span>
                </label>
                <br />
                <input
                  className="confirm_password_field"
                  type="password"
                  name=""
                  id=""
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="off"
                />
                {error && (
                  <p
                    style={{
                      fontFamily: "Noto Sans",
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#FF3B30",
                      marginBottom: "2px",
                    }}
                  >
                    {error}
                  </p>
                )}
              </div>
              <div>
                <button className="submit_btn">
                  <p className="submit_btn_text" onClick={handleSubmit}>
                    Submit
                  </p>
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col className="right text-center" md={5}>
          <span className="right_box_text ">
            AI-Driven Insights for Seamless Compliance <br /> And Security
            Across Multi-Cloud Ecosystems.
          </span>
          <div className="right my-5">
            <img className="rs_1 " src={RSimage} alt="" />
          </div>
        </Col>
      </Row>
      <Modal show={showConfirmMsg} centered className="modalStyle-vc">
        <Modal.Body className="dialog-body">
          <div className="submit-icon">
            <img src={submitIcon} alt="Ok" />
          </div>
          <h3 className="verification-text">Password Set</h3>
          <p className="verification-content">
            Password has been set successfully.
          </p>
        </Modal.Body>
        <Modal.Footer className="model_footer">
          <Button className="ok_btn" onClick={() => handleOk()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LandingPageSP;
