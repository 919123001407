import React, { useState } from 'react'
import '../styles/userManagement.css'
import { Container, Button, Table,Form, Modal, Dropdown } from 'react-bootstrap'
import Edit from '../Icons/edit.svg'
import Delete from '../Icons/delete.svg'
import searchIcon from '../Icons/searchIcon.svg'
import cancel from '../Icons/cancel.svg'
import rejectIcon from '../Icons/reject.svg'
import submitIcon from '../Icons/submitIcon.svg'
import '../styles/businessUnit.css'

const BusinessUnit = () => {

  const[isAddBusiness, setIsAddBusiness] = useState(false);
  const[dialogNewBU, setDialogNewBU] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const[dialogEditBU, setDialogEditBU] = useState(false);

  const users = [
    {slNo: 1, unitName:'CIS'},
    {slNo: 2, unitName:'CRS'},
    {slNo: 3, unitName:'Digital'},
    {slNo: 4, unitName:'United State'},
  ]
  const handleAddBusiness = () => {
    setIsAddBusiness(true);
    setDialogNewBU(false);
  }
  const handleSaveButton = () => {
    // setIsAddBusiness(false);
    setDialogNewBU(true);
  }
  const handleOkBtn =()=> {
    setDialogNewBU(false);
    setIsAddBusiness(false);
  }
  const handleEditOkBtn =()=> {
    setDialogEditBU(false);
    setIsEdit(false);
  }
  const handleEdit = () => {
    setIsEdit(true);
  }
  const handleEditButton = () => {
    setDialogEditBU(true);
  } 
  const handleCreateCancel =() => {
    setIsAddBusiness(false);
  }
  const handleEditCancel =() => {
    setIsEdit(false);
  }

  return (
    <Container>
      {!isAddBusiness && !isEdit? (<div className="container">
        <div className="top-panel-container" style={{display:'flex', justifyContent:'space-between'}}>
          <div className="search-bar-container">
            <img src={searchIcon} alt='Seach Icon' className='search-icon' />
            <input
              type="text"
              placeholder="Search Business Unit"
              className="search-input"
            />
             <span className="reset-text">Reset</span>
             <Button className='search-button'>Search</Button>
          </div>
          <div><Button className='add-business-btn' onClick={handleAddBusiness}>+Add Business Unit</Button></div>


      </div>
        {/* <Table className="custom-table" bordered hover>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Business Unit Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) =>(
                <tr key={user.slNo}>
                    <td>{user.slNo}</td>
                    <td>{user.unitName}</td>
                    <td>
                        <img src={Edit} alt='Edit' className='action-edit'onClick={handleEdit} />
                        <img src={Delete} alt='Delete' className='action-delete' onClick={() => setShowDeleteDialog(true)}/>
                    </td> 
                </tr>
            ))}
           </tbody>
        </Table> */}
        <Modal
            show={showDeleteDialog}
            onHide={() => setShowDeleteDialog(false)}
            centered
            className="custom-modal"
        >
            <Modal.Header>
                <Modal.Title style={{flex: 1}}>Delete Business Unit</Modal.Title>
                    <img src={cancel} alt="Close" onClick={() => setShowDeleteDialog(false)}/>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete ”CRS” Business Unit?
            </Modal.Body>
            <Modal.Footer>
                <Button className='confirm-btn' onClick={() => setShowDeleteDialog(false)}>Confirm</Button>
                <Button className="cancel-btn" onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
      </div>):(isAddBusiness && !isEdit ?(<div className="container">
                <div className='create-bu-title'>Create Business Unit</div>
                <div className='create-bu-box' >
                    <div className="create-bu-field">
                        <label htmlFor="name">Name<span style={{color:'#FF2929'}}>*</span></label>
                        <input type="text" />
                    </div>
                    <div className="create-bu-field">
                        <label htmlFor="description">Description<span style={{color:'#FF2929'}}>*</span></label>
                        <textarea  rows="3" />
                    </div>
                    <div className='create-bu-actions'>
                        <Button  className='save-button' onClick={handleSaveButton}>Save</Button>
                        <Button className='cancel-button'onClick={handleCreateCancel}>Cancel</Button>
                    </div>
                    <Modal show={dialogNewBU} centered className="modal-createbu">
                        <Modal.Body className="createbu-dialog-body">
                            <div className="submit-icon">
                                <img src={submitIcon} alt="Ok" />
                            </div>
                            <h3 className="user-createbu-text">New Business Unit Added</h3>
                            <p className="success-createbu-content">
                            New Business unit has been successfully added.
                            </p>
                        </Modal.Body>
                        <Modal.Footer className="createbu-model-footer">
                            <Button
                                className="ok_button"
                                onClick={handleOkBtn}
                            >
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                 </div> 
                 </div>):(<div className="container">
                <div className='create-bu-title'>Edit Business Unit</div>
                <div className='create-bu-box' >
                    <div className="create-bu-field">
                        <label htmlFor="name">Name<span style={{color:'#FF2929'}}>*</span></label>
                        <input type="text" />
                    </div>
                    <div className="create-bu-field">
                        <label htmlFor="description">Description<span style={{color:'#FF2929'}}>*</span></label>
                        <textarea  rows="3" />
                    </div>
                    <div className='create-bu-actions'>
                        <Button  className='save-button' onClick={handleEditButton}>Save</Button>
                        <Button className='cancel-button'onClick={handleEditCancel}>Cancel</Button>
                    </div>
                    <Modal show={dialogEditBU} centered className="modal-editbu">
                        <Modal.Body className="editbu-dialog-body">
                            <div className="submit-icon">
                                <img src={submitIcon} alt="Ok" />
                            </div>
                            <h3 className="user-editbu-text">Changes Updated Successfully</h3>
                            <p className="success-editbu-content">
                            Changes in Business Unit has been updated successfully
                            </p>
                        </Modal.Body>
                        <Modal.Footer className="editbu-model-footer">
                            <Button
                                className="ok_button"
                                onClick={handleEditOkBtn}
                            >
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                 </div> 
                 </div>))}
    </Container>
    )}
export default BusinessUnit