import React, { useEffect, useState } from "react";
import "../styles/alerts.css";
import "../styles/alertTable.css";
import "../styles/suppressConfirmation.css";
import "../styles/controlCheck.css";
import arrowLeft from "../Icons/controlCheckArrow.svg";
import healIcon from "../Icons/heal.svg";
import tooltip from "../Icons/tooltip.svg";
import greenCircle from "../Icons/roundCircleGreen.svg";
import redCircle from "../Icons/roundCircleRed.svg";
import orangeCircle from "../Icons/roundCircleOrange.svg";
import submitIcon from "../Icons/submitIcon.svg";

import {
  Button,
  Col,
  Dropdown,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

let dataState = {};

export const Alerts = ({
  filterAlert,
  handleFilterAlertChange,
  handleResetAlert,
  isDropdownEnabled,
}) => {
  const [cloudTypes, setCloudTypes] = useState([]);

  useEffect(() => {
    const fetchCloudTypes = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/cloudtypemaster"
        );
        if (response.status === 200) {
          const { body } = response.data;
          if (Array.isArray(body)) {
            setCloudTypes(body);
          } else {
            console.error("Body is not an array:", body);
          }
        }
      } catch (error) {
        console.error("Error fetching Cloud Types:", error);
      }
    };

    fetchCloudTypes();
  }, []);

  return (
    <>
      <div>
        <span className="heading">Alerts</span>
      </div>
      <div className="sub_content mt-3">
        <label className="cloud_type" htmlFor="">
          Cloud Type
        </label>
        <Dropdown
          onSelect={(eventKey) => handleFilterAlertChange("csp", eventKey)}
        >
          <Dropdown.Toggle variant="" className="cloud_service">
            <span className="choose_cloud_service">
              {filterAlert.csp || "Choose Cloud Service"}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown_menu_cloud_service">
            {cloudTypes
              .sort((a, b) => a[1].localeCompare(b[1]))
              .map((cloud) => (
                <Dropdown.Item
                  key={cloud[0]}
                  eventKey={cloud[1]}
                  className="dropdown_item_cloud_service"
                >
                  {cloud[1]}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        <label className="source_type" htmlFor="">
          Source Type
        </label>
        <Dropdown
          onSelect={(eventKey) =>
            handleFilterAlertChange("alertSource", eventKey)
          }
        >
          <Dropdown.Toggle
            variant="secondary"
            className="service_type"
            disabled={!isDropdownEnabled}
          >
            <span>{filterAlert.alertSource || "Choose Service Type"} </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown_menu_service_type">
            <Dropdown.Item
              className="dropdown_item_service_type"
              eventKey="Native service - AWS"
            >
              Native service - AWS
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span className="reset" onClick={handleResetAlert}>
          Reset
        </span>
        {/* <button className="search_btn">
          <span className="search">Search</span>
        </button> */}
      </div>
    </>
  );
};

export const AlertTable = ({
  suppress,
  setSupress,
  filterAlert,
  searchTerm,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleHeal, setModalVisibleHeal] = useState(false);

  const handleSuppress = () => {
    if (suppress === 1) {
      setSupress(3);
    }
  };
  const handleAlertidClick = (items) => {
    dataState = items;
    console.log("items", dataState);
    if (suppress === 1) {
      setSupress(2);
    }
  };

  const getSeverityImage = (severity) => {
    switch (severity) {
      case "High":
        return redCircle;
      case "Medium":
        return orangeCircle;
      case "Low":
        return greenCircle;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/aws/alert"
        );

        const data = response.data.map((item) => JSON.parse(item)); // Parse each JSON string
        // setUsers(data);
        console.log("Data", data);
        console.log("Severity", data.severity);

        const filtered = data.filter(
          (item) =>
            (!filterAlert.name ||
              item.name
                .toLowerCase()
                .includes(filterAlert.name.toLowerCase())) &&
            (!filterAlert.alertSource ||
              item.alertSource === filterAlert.alertSource) &&
            (!filterAlert.csp || item.csp === filterAlert.csp) &&
            (!searchTerm ||
              item.name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredData(filtered);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [filterAlert, searchTerm]);

  // const formattedDate = (timestamp) => {
  //   const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = date.toLocaleString("en-GB", { month: "short" });
  //   const year = String(date.getFullYear()).slice(-2);
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");

  //   return `${day}-${month}-${year} ${hours}:${minutes}`;
  // };

  const formattedDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", { month: "short" });
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleHeal = () => {
    setModalVisible(true);
    setTimeout(() => {
      setModalVisible(false);
      setModalVisibleHeal(true);
    }, 5000);
  };
  const handleOk = () => {
    setModalVisibleHeal(false);
  };
  return (
    <div style={{ maxHeight: "500px", overflow: "auto", maxWidth: "100%" }}>
      <Table
        className="custom-table-cloud-account"
        style={{ minWidth: "1550px", width: "100%" }}
      >
        <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
          <tr>
            <th className="table_heading">Alert ID</th>
            <th className="table_heading">Alert Source</th>
            <th className="table_heading">Asset ID</th>
            <th className="table_heading">Resource</th>
            <th className="table_heading">Asset</th>
            <th className="table_heading">Region</th>
            <th className="table_heading">Control Check Name</th>
            <th className="table_heading">Severity</th>
            <th className="table_heading">
              CSP
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover className="custom_tooltip">
                    Cloud Service Provider
                  </Popover>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <img
                    src={tooltip}
                    alt=""
                    ref={ref}
                    roundedCircle
                    {...triggerHandler}
                    className="d-inline-flex align-items-center mx-1"
                  />
                )}
              </OverlayTrigger>
            </th>
            <th className="table_heading">Opened At</th>
            <th className="table_heading">Status</th>
            <th className="table_heading">Action</th>
            <th className="table_heading">Heal</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "70%",
                marginLeft: "500px",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            filteredData
              .slice()
              .sort((a, b) => a.alertId - b.alertId)
              .map((items, index) => (
                <tr key={items.alertId}>
                  <td className="td_items">
                    <Link
                      onClick={() => handleAlertidClick(items)}
                      className="alertId_link"
                    >
                      {items.alertId}
                    </Link>
                  </td>
                  <td className="td_items">{items.alertSource}</td>
                  <td className="td_items">{items.assetId}</td>
                  <td className="td_items">{items.resource}</td>
                  <td className="td_items">{items.asset}</td>
                  <td className="td_items">{items.region}</td>
                  <td className="td_items">{items.controlCheckName}</td>
                  <td className="td_items" style={{ textAlign: "center" }}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover
                          style={{
                            padding: "5px",
                            fontFamily: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "19.07px",
                          }}
                        >
                          {items.severity}
                        </Popover>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <img
                          src={getSeverityImage(items.severity)}
                          alt={items.severity}
                          ref={ref}
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        />
                      )}
                    </OverlayTrigger>
                  </td>
                  <td className="td_items">{items.csp}</td>
                  <td>{formattedDate(items.openedAt)} (UTC)</td>

                  <td className="td_items">{items.status}</td>

                  <td className="td_items">
                    <button
                      disabled={items.status === "Closed"}
                      className="supress_btn td_items"
                      onClick={handleSuppress}
                    >
                      Suppress
                    </button>
                  </td>
                  <td className="td_items">
                    <img
                      src={healIcon}
                      alt="heal_icon"
                      style={{ cursor: "pointer" }}
                      onClick={handleHeal}
                    />
                  </td>
                </tr>
              ))
          )}
        </tbody>
      </Table>
      <Modal show={modalVisible} centered className="modal-createAlert">
        <Modal.Body className="createbu-dialog-body">
          {/* <div className="submit-icon">
            <img src={submitIcon} alt="Ok" />
          </div> */}
          <h3 className="user-createbu-text">Self-Heal Initiated</h3>
          <p className="success-createbu-content">
            The self-heal (auto-remediation) has been initiated for the selected
            Cloud asset.
          </p>
        </Modal.Body>
        <Modal.Footer className="createbu-model-footer">
          <Button disabled className="ok_button_alert">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalVisibleHeal} centered className="modal-createHeal">
        <Modal.Body className="createbu-dialog-body">
          <div className="submit-icon">
            <img src={submitIcon} alt="Ok" />
          </div>
          <h3 className="user-createbu-text">Self-Heal Completed</h3>
          <p className="success-createbu-content">
            The self-heal (auto-remediation) has been successfully completed.
          </p>
        </Modal.Body>
        <Modal.Footer className="createbu-model-footer">
          <Button className="ok_button_heal" onClick={handleOk}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const SuppressConfirmation = ({ setSupress }) => {
  const handleCancel = () => {
    setSupress(1);
  };
  const hanleConfirm = () => {
    setSupress(1);
  };
  return (
    <>
      <div className="suppressConfirmation">Suppress Confirmation</div>
      <div className="suppress_container_box mt-4">
        <span className="want_to_continue">
          This action will suppress this alert for this application. Do you want
          to continue?
        </span>
        <div>
          <textarea
            name=""
            className="box_container2 mt-2"
            placeholder="Please enter your comments to suppress."
          ></textarea>
        </div>
        <div className="mt-1">
          <span className="alert_suppress_text ">
            Alert Suppress Information
          </span>
          <div className="mt-3">
            <label htmlFor="" className="date_text">
              Date<span className="astrick">*</span>
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="date" name="" id="" className="date_field" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label htmlFor="" className="time_text">
              Time<span className="astrick">*</span>
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="time" name="" id="" className="time_input" />
          </div>
          <div className="btn_container mt-4 d-flex justify-content-center">
            <button onClick={hanleConfirm} className="confirm_btn">
              Confirm
            </button>
            <button onClick={handleCancel} className="mx-3 cancel_btn">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const ControlCheck = ({ setSupress }) => {
  console.log("first");
  console.log("data", dataState);
  const formattedDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", { month: "short" });
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  const getSeverityImage = (severity) => {
    switch (severity) {
      case "High":
        return redCircle;
      case "Medium":
        return orangeCircle;
      case "Low":
        return greenCircle;
      default:
        return null;
    }
  };
  return (
    <>
      <div>
        <img
          src={arrowLeft}
          alt=""
          style={{ position: "relative", bottom: "2px", cursor: "pointer" }}
          onClick={() => setSupress(1)}
        />
        <span className="controlCheckName_text mx-2">
          Control Check Name: {dataState.controlCheckName}
        </span>
        <div className="controlCheck_box mt-3">
          <Row>
            <Col md={3} className="left_col_datas">
              Control Check Name
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.controlCheckName}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Control Check Description
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.controlCheckDescription}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Alert Source
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.alertSource}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Technical Control Name
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.technicalControlName}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Technical Control Description
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.technicalControlDescription}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Recommendation
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.recomendation}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Status
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.status}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Severity
            </Col>
            <Col className="right_col_datas" md={9}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover
                    style={{
                      padding: "5px",
                      fontFamily: "Noto Sans",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "19.07px",
                    }}
                  >
                    {dataState.severity}
                  </Popover>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <img
                    src={getSeverityImage(dataState.severity)}
                    alt={dataState.severity}
                    ref={ref}
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                  />
                )}
              </OverlayTrigger>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Last Updated
            </Col>
            <Col className="right_col_datas" md={9}>
              {formattedDate(dataState.lastUpdated)}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Cloud Service Provider
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.csp}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Region
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.region}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Resource
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.resource}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Asset
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.asset}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Asset ID
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.assetId}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="left_col_datas" md={3}>
              Cloud ID
            </Col>
            <Col className="right_col_datas" md={9}>
              {dataState.cloudId}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
