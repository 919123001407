import { Dropdown, Table } from "react-bootstrap";
import React, { useState } from "react";
import iconOpen from "../Icons/iconOpen.svg";
import iconClose from "../Icons/iconClose.svg";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Edit from "../Icons/edit.svg";
import Delete from "../Icons/delete.svg";
import { Modal } from "react-bootstrap";
import submitIcon from "../Icons/submitIcon.svg";
import cancel from "../Icons/cancel.svg";
import "../styles/integrationAccountTable.css";

export const Integration = ({ edit, setEdit }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };
  return (
    <Container>
      <Row>
        <Col>
          <div>
            <div className={isOpen ? "mb-3" : ""}>
              {edit ? (
                <div>
                  <span className="add_account">edit the page</span>
                  <div
                    style={{
                      border: "1px solid #C5c5c5",
                      borderRadius: "16px",
                      padding: "20px",
                    }}
                    className="mt-3"
                  >
                    <Row>
                      <Col className="form_group">
                        <label htmlFor="" className="cloud_service_text">
                          Tool Name<span className="astrick">*</span>
                        </label>
                        &nbsp;
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="dropdown_integration"
                            disabled
                          >
                            <span>Service Now</span>
                          </Dropdown.Toggle>
                        </Dropdown>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Tool URL<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Client ID<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Username<span className="astrick">*</span>
                        </label>
                        &nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                      <Col></Col>
                    </Row>
                    <br />
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Button
                          onClick={() => {
                            setEdit(false);
                          }}
                          style={{
                            width: "84px",
                            height: "48px",
                            borderRadius: "8px",
                            backgroundColor: "#2787e4",
                            border: "none",
                            font: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "21.79px",
                          }}
                          className="mx-3"
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            setEdit(false);
                          }}
                          style={{
                            width: "99px",
                            height: "48px",
                            borderRadius: "8px",
                            backgroundColor: "#ff2929",
                            border: "none",
                            font: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "21.79px",
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                <span className="add_account">Add Account</span>
              )}
              {edit ? (
                ""
              ) : (
                <button onClick={toggleDropdown} className="dropdown_btn">
                  {isOpen ? (
                    <img src={iconOpen} alt="" />
                  ) : (
                    <img src={iconClose} alt="" />
                  )}
                </button>
              )}
            </div>

            {isOpen && (
              <div className="cloud_service_container">
                {selectedOption === "Active Directory" ? (
                  <ActiveDirectory
                    setIsOpen={setIsOpen}
                    setSelectedOption={setSelectedOption}
                  />
                ) : selectedOption === "Service Now" ? (
                  <ServiceNow
                    setIsOpen={setIsOpen}
                    setSelectedOption={setSelectedOption}
                  />
                ) : (
                  <div className="form_group">
                    <label className="cloud_service_text">
                      Tool Name<span className="astrick">*</span>
                    </label>
                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle
                        variant="success"
                        className="dropdown_integration mx-4"
                      >
                        <span>Choose tool name</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown_menu_integration">
                        <Dropdown.Item
                          className="dropdown-item-integration"
                          eventKey="Active Directory"
                        >
                          Active Directory
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item-integration"
                          eventKey="Service Now"
                        >
                          Service Now
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export const ActiveDirectory = ({ setIsOpen, setSelectedOption }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleOkBtn = () => {
    setModalShow(false);
    setIsOpen(false);
    setSelectedOption(null);
  };

  const handleCanceldropdown = () => {
    setSelectedOption(null);
    setIsOpen(false);
  };

  return (
    <Container>
      <Row>
        <Col className="form_group">
          <label htmlFor="" className="cloud_service_text">
            Tool Name<span className="astrick">*</span>
          </label>
          &nbsp;&nbsp;&nbsp;
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              className="dropdown_activeDirectory mx-4"
            >
              <span>Active Directory</span>
            </Dropdown.Toggle>
          </Dropdown>
        </Col>
        <Col></Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <label htmlFor="" className="acc_owner_text">
            Tool URL<span className="astrick">*</span>
          </label>
          &nbsp; &nbsp; &nbsp;
          <input type="text" className="inputtext_aws" />
        </Col>
        <Col>
          <label htmlFor="" className="acc_owner_text">
            Tenant ID<span className="astrick">*</span>
          </label>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <input type="text" className="inputtext_aws" />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <label htmlFor="" className="acc_owner_text">
            Client ID<span className="astrick">*</span>
          </label>
          &nbsp; &nbsp; &nbsp;
          <input type="text" className="inputtext_aws" />
        </Col>
        <Col>
          <label htmlFor="" className="acc_owner_text">
            Client Secret<span className="astrick">*</span>
          </label>
          <input type="text" className="inputtext_aws" />
        </Col>
      </Row>
      <br />
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            onClick={() => setModalShow(true)}
            style={{
              width: "84px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#2787e4",
              border: "none",
              font: "Noto Sans",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "21.79px",
            }}
            className="mx-3"
          >
            Save
          </Button>
          <Button
            onClick={handleCanceldropdown}
            style={{
              width: "99px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#ff2929",
              border: "none",
              font: "Noto Sans",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "21.79px",
            }}
          >
            Cancel
          </Button>
          <Modal
            show={modalShow}
            centered
            className="modalStyle-msg-integration"
          >
            <Modal.Body className="dialog-body">
              <div className="submit-icon-integration">
                <img src={submitIcon} alt="Ok" />
              </div>
              <div className="content">
                <h3 className="integration-tool-text1">
                  Tool Added Successfully
                </h3>
                <p className="integration-tool-text2">
                  The toolname has been integrated successfully
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="model_footer_integration">
              <Button className="ok_btn" onClick={handleOkBtn}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export const ServiceNow = ({ setIsOpen, setSelectedOption }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleOkBtnServiceNow = () => {
    setModalShow(false);
    setIsOpen(false);
    setSelectedOption(null);
  };

  const handleCancelDropdownServicenow = () => {
    setSelectedOption(null);
    setIsOpen(false);
  };

  return (
    <Container>
      <Row>
        <Col className="form_group">
          <label htmlFor="" className="cloud_service_text">
            Tool Name<span className="astrick">*</span>
          </label>
          &nbsp;&nbsp;&nbsp;
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              className="dropdown_servcenow mx-4"
            >
              <span>Service Now</span>
            </Dropdown.Toggle>
          </Dropdown>
        </Col>
        <Col></Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <label htmlFor="" className="acc_owner_text">
            Tool URL<span className="astrick">*</span>
          </label>
          &nbsp; &nbsp; &nbsp;
          <input type="text" className="inputtext_aws" />
        </Col>
        <Col>
          <label htmlFor="" className="acc_owner_text">
            Authorization Token<span className="astrick">*</span>
          </label>
          <input type="text" className="inputtext_aws" />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <label htmlFor="" className="acc_owner_text">
            Username<span className="astrick">*</span>
          </label>
          &nbsp;
          <input type="text" className="inputtext_aws" />
        </Col>
        <Col>
          <label htmlFor="" className="acc_owner_text">
            Password<span className="astrick">*</span>
          </label>
          &nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <input type="password" className="inputtext_aws" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <Button
            onClick={() => setModalShow(true)}
            style={{
              width: "84px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#2787e4",
              border: "none",
              font: "Noto Sans",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "21.79px",
            }}
            className="mx-3"
          >
            Save
          </Button>
          <Button
            onClick={handleCancelDropdownServicenow}
            style={{
              width: "99px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#ff2929",
              border: "none",
              font: "Noto Sans",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "21.79px",
            }}
          >
            Cancel
          </Button>
          <Modal
            show={modalShow}
            centered
            className="modalStyle-msg-servicenow"
          >
            <Modal.Body className="dialog-body">
              <div className="submit-icon-servicenow">
                <img src={submitIcon} alt="Ok" />
              </div>
              <div className="content">
                <h3 className="servicenow-tool-text1">
                  Tool Added Successfully
                </h3>
                <p className="servicenow-tool-text2">
                  The toolname has been integrated successfully
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="model_footer_servicenow">
              <Button className="ok_btn" onClick={handleOkBtnServiceNow}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export const IntegrationAccountTable = ({ edit, setEdit }) => {
  const users = [
    {
      sNo: 1,
      toonName: "Active Directory",
      url: "https://login.microsoftonline.com",
      userName: "NA",
      clientId: "869bjrk0ygdejb-47y3nbknc-8u89",
    },
    {
      sNo: 12,
      toonName: "Active Directory",
      url: "https://login.microsoftonline.com",
      userName: "NA",
      clientId: "869bjrk0ygdejb-47y3nbknc-8u89",
    },
    {
      sNo: 123,
      toonName: "Active Directory",
      url: "https://login.microsoftonline.com",
      userName: "NA",
      clientId: "869bjrk0ygdejb-47y3nbknc-8u89",
    },
    {
      sNo: 1234,
      toonName: "Active Directory",
      url: "https://login.microsoftonline.com",
      userName: "NA",
      clientId: "869bjrk0ygdejb-47y3nbknc-8u89",
    },
    {
      sNo: 12345,
      toonName: "Active Directory",
      url: "https://login.microsoftonline.com",
      userName: "NA",
      clientId: "869bjrk0ygdejb-47y3nbknc-8u89",
    },
    {
      sNo: 123456,
      toonName: "Active Directory",
      url: "https://login.microsoftonline.com",
      userName: "NA",
      clientId: "869bjrk0ygdejb-47y3nbknc-8u89",
    },
  ];

  const [modalShow, setModalShow] = useState(false);

  const handleEdit = () => {
    setEdit(true);
    console.log("button clicked edit");
  };

  const setShowDeleteDialog = () => {
    setModalShow(true);
  };

  const handleCloseDialog = () => {
    setModalShow(false);
  };

  return (
    <div>
      {edit ? (
        ""
      ) : (
        <Table className="custom-table-cloud-account">
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Tool Name</th>
              <th>URL</th>
              <th>User Name</th>
              <th>Client ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.sNo}>
                <td>{user.sNo}</td>
                <td>{user.toonName}</td>
                <td>{user.url}</td>
                <td>{user.userName}</td>
                <td>{user.clientId}</td>

                <td>
                  <img
                    src={Edit}
                    alt="Edit"
                    className="action-edit mx-2"
                    onClick={() => handleEdit(user)}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="action-delete"
                    onClick={() => setShowDeleteDialog(true)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <Modal
            show={modalShow}
            // onHide={handleCloseDialog}
            centered
            className="modalStyleIntegration"
          >
            <Modal.Header className="model_header">
              <div className="header-content">
                <div>
                  <Modal.Title>Delete Business Unit</Modal.Title>
                  <p className="modalText">
                    Are you sure you want to delete ”sample text” Integration?
                  </p>
                </div>
                <div className="close-icon" onClick={handleCloseDialog}>
                  <img src={cancel} alt="Close" />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="dialog-body"></Modal.Body>
            <Modal.Footer className="model_footer">
              <Button
                className="send_btn"
                // onClick={handleSendButton}
              >
                Send
              </Button>
              <Button
                className="back_to_login"
                // onClick={handleCloseDialog}
              >
                Back to Login
              </Button>
            </Modal.Footer>
          </Modal>
        </Table>
      )}
    </div>
  );
};
