import "./App.css";
import RouterMain from "./Components/Router/routerMain";

import "./App.css";

function App() {
  return (
    <div>
      <RouterMain />
    </div>
  );
}

export default App;
