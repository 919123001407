import React, { useState } from "react";
import "../styles/homePage.css";
import homePageImage from "../Images/homePageImage.svg";
import gif from "../Images/gif.svg";
import carouselImage from "../Images/caraoselImg.svg";
import carouselImage2 from "../Images/carouselImg2.svg";
import carouseiImgage3 from "../Images/carouselImage3.svg";
import carouseiImgage4 from "../Images/carouselImage4.svg";
import carouseiImgage5 from "../Images/carouselImage5.svg";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import arrowLeft from "../Icons/arrowleft.svg";
// import arrowRight from "../Icons/arrowright.svg";

const HomePage = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Container>
      <Row>
        <Col style={{ marginRight: "10%", paddingLeft: "30px" }}>
          <div className="text-content">
            <h1 className="home-title">Cloud Compliance Shield</h1>
            <p className="welcome-text">
              Welcome to Wipro's Cloud Compliance Shield - Your Gateway to
              simplified multi-cloud security and compliance, powered by AWS.
              This platform is your strategic ally in demystifying the
              complexities of multi-cloud environments, offering you an
              unmatched view into your compliance and security landscapes. With
              Cloud Compliance Shield, proactive Identification, Assessment, and
              Mitigation of security vulnerabilities are at your
              fingertips.Welcome to Wipro’s Cloud Compliance Shield – Your
              Gateway to simplified multi-cloud security and compliance, powered
              by AWS. This platform is your strategic ally in demystifying the
              complexities of multi-cloud environments, offering you an
              unmatched view into your compliance and security landscapes. With
              Cloud Compliance Shield, proactive Identification, Assessment, and
              Mitigation of security vulnerabilities are at your fingertips
            </p>
          </div>
        </Col>
        <Col>
          <div
            style={{
              position: "relative",
              width: "430px",
              height: "430px",
              // float: "right",
            }}
          >
            <img
              className="welcome-img1 d-flex justify-content-end"
              src={homePageImage}
              alt="/icons"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: "1",
              }}
            />
            <img
              src={gif}
              alt=""
              style={{
                width: "32%" /* Adjust size as needed */,
                height: "auto",
                position: "absolute",
                top: "51%" /* Center vertically */,
                left: "52%" /* Center horizontally */,
                transform:
                  "translate(-50%, -50%)" /* Move the image to the center */,
                zIndex: "2",
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingLeft: "30px", marginBottom: "20px" }}>
          <div
            className="mt-5"
            style={{
              overflow: "hidden",
              position: "relative",
              border: "1px solid #0052d4",
              height: "154px",
              width: "1006px",
              borderRadius: "10px",
              paddingLeft: "40px",
            }}
          >
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              style={{ height: "200px" }}
            >
              <Carousel.Item interval={2000}>
                <img src={carouselImage} alt="" />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img src={carouselImage2} alt="" />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img src={carouseiImgage3} alt="" />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img src={carouseiImgage4} alt="" />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img src={carouseiImgage5} alt="" />
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
