import React, { useEffect, useState } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import searchIcon from "../Icons/searchIcon.svg";
import submitIcon from "../Icons/submitIcon.svg";
import "../styles/regulatoryStandard.css";
import axios from "axios";

const RegulatoryStandards = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/regulatorystandards"
  //       );
  //       setData(response.data);
  //       setFilteredData(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/regulatorystandards"
        );
        const data = response.data;

        // Create an object to store the checkbox status
        const checkboxStatus = {};
        data.forEach((item) => {
          checkboxStatus[item.regulatory_standard_name] =
            item.status === "True";
        });
        console.log("checkboxStatus", checkboxStatus);

        setData(data);
        setFilteredData(data);
        setCheckedItems(checkboxStatus);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = () => {
    if (searchTerm) {
      const filtered = data.filter((item) =>
        item.regulatory_standard_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data); // Reset to full data if search term is empty
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setFilteredData(data);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSave = async () => {
    console.log("saved clicked");
    setIsSaved(true);
    setModalVisible(true);

    // Prepare the data to be saved
    const saveData = data
      .filter((item) => item.regulatory_standard_id) // Filter out items with empty regulatory_standard_id
      .map((item) => ({
        regulatory_standard_id: item.regulatory_standard_id,
        status: checkedItems[item.regulatory_standard_name] ? "True" : "False",
      }));
    // Set up headers
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer YOUR_ACCESS_TOKEN", // Replace with your actual token if needed
    };
    try {
      const response = await axios.post(
        "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/regulatorystandards/save_button",
        { regulatory_standards: saveData },
        { headers }
      );
      console.log("Save response:", response.data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleOk = () => {
    setModalVisible(false);
    // if (!isSaved) {
    //   setCheckedItems({});
    // }
    // setIsSaved(false);
  };

  return (
    <div>
      <div className="title">Supported Regulatory Standards</div>
      <div className="top-panel">
        <div className="search-bar">
          <img src={searchIcon} alt="Seach Icon" className="search-icon" />
          <input
            type="text"
            placeholder="Search regulatory standard name"
            className="search-input-regulatory"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <Button onClick={handleSearch} className="search-button-regulatory">
            Search
          </Button>
          <span className="reset-text-regulatory" onClick={handleReset}>
            Reset
          </span>
        </div>
        <div>
          <Button className="save-btn-regulatory" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
      <div>
        {loading ? (
          <div
            style={{
              position: "relative",
              top: "230px",
              left: "43%",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table className="custom-table-regulatory" bordered>
            <thead>
              <tr>
                <th style={{ width: "75%" }}>Regulatory Standard Name</th>
                <th>Policy Control Count</th>
              </tr>
            </thead>
            {/* <tbody>
              {filteredData.map((user) => (
<tr>
<td>
<input
                      type="checkbox"
                      name="user.regulatory_standard_name"
                      checked={checkedItems[user.regulatory_standard_name]}
                      onChange={handleCheckboxChange}
                      style={{ marginRight: "20px" }}
                      className="checkbox"
                    />
                    {user.regulatory_standard_name}
</td>
<td>{user.policy_control_count}</td>
</tr>
              ))}
</tbody> */}

            <tbody>
              {filteredData
                .sort((a, b) =>
                  a.regulatory_standard_name.localeCompare(
                    b.regulatory_standard_name
                  )
                )
                .map((user) => (
                  <tr key={user.regulatory_standard_id}>
                    <td>
                      <input
                        type="checkbox"
                        name={user.regulatory_standard_name}
                        checked={
                          checkedItems[user.regulatory_standard_name] || false
                        }
                        onChange={handleCheckboxChange}
                        style={{ marginRight: "20px" }}
                        className="checkbox"
                      />
                      {user.regulatory_standard_name}
                    </td>
                    <td>{user.policy_control_count}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </div>
      <Modal show={modalVisible} centered className="modal-createbu">
        <Modal.Body className="createbu-dialog-body">
          <div className="submit-icon">
            <img src={submitIcon} alt="Ok" />
          </div>
          <h3 className="user-createbu-text">Regulatory Standards</h3>
          <p className="success-createbu-content">
            Regulatory Standards have been saved successfully
          </p>
        </Modal.Body>
        <Modal.Footer className="createbu-model-footer">
          <Button className="ok_button" onClick={handleOk}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RegulatoryStandards;
