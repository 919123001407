import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../styles/logIn.css";
import cancel from "../Icons/cancel.svg";
import submitIcon from "../Icons/submitIcon.svg";
import axios from "axios";
import { UserContext } from "../Redux/userContext";
// import { useAuth } from "../Router/authContext";

const SignIn = () => {
  const navigate = useNavigate();
  // const { login } = useAuth();
  const { setEmail } = useContext(UserContext);

  // state to open Forgot Password Dialog Box
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [showResetMsg, setShowResetMsg] = useState(false);
  const [email, setEmailInput] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const validateForm = () => {
    if (!email || !password) {
      setError("Please fill all the mandatory Fields.");
      return false;
    }
    setError("");
    return true;
  };

  // function to navigate to Dashboard
  const redirectToDashboard = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/login",
          {
            username: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response data", response.data);
        console.log("response data message", response.data.errorMessage);
        console.log("response data error type", response.data.errorType);
        if (
          response.data &&
          response.data.errorType &&
          response.data.errorType === "UserNotFoundException"
        ) {
          setError("Incorrect Email & Password combination. Please check.");
        } else if (
          response.data &&
          response.data.errorType &&
          response.data.errorType === "UnboundLocalError"
        ) {
          setError("Incorrect password. Please try the correct password.");
        } else if (response.data.principalId) {
          const effectValue = response.data.policyDocument.Statement[0].Effect;
          if (effectValue === "Allow") {
            console.log("Login successfully");
            setEmail(email);
            // login();
            navigate("/dashboard");
          } else {
            setError("Incorrect password. Please try the correct password.");
          }
        } else {
          setError("Failed to log in. Please try again.");
        }
      } catch (error) {
        console.error("Error logging in:", error);
      }
    }
  };

  // function to open Forgot Password Dialog Box
  const handleForgotPassword = (e) => {
    e.preventDefault();
    setShowDialogBox(true);
  };

  // function to close Reset Password Dialog Box
  const handleCloseDialog = () => {
    setShowDialogBox(false);
  };

  // function to open successfully Reset Message Dialog Box
  const handleSendButton = (e) => {
    setShowResetMsg(true);
    setShowDialogBox(false);
  };

  return (
    <div>
      <div className="credential_container">
        <div className="email_container">
          <label className="email_text" htmlFor="">
            Email
            <span className="astrick">*</span>
          </label>
          <br />
          <input
            className="email_field"
            type="email"
            value={email}
            name=""
            id=""
            placeholder="Enter your email"
            onChange={(e) => setEmailInput(e.target.value)}
            autoComplete="off"
          />
          <br />
        </div>
        <div className="password_container">
          <label className="password_text" htmlFor="">
            Password
            <span className="astrick">*</span>
          </label>
          <br />
          <input
            className="password_field"
            type="password"
            name=""
            id=""
            value={password}
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
          />
          {error && (
            <span
              style={{
                color: "#FF3B30",
                fontFamily: "Noto Sans",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              {error}
            </span>
          )}
        </div>
        <div>
          <button className="logIn_btn" onClick={redirectToDashboard}>
            <p className="logIn_btn_text">Log In</p>
          </button>
        </div>
      </div>
      <div>
        <Link className="forget_pass_text" onClick={handleForgotPassword}>
          Forgot your password?
        </Link>
      </div>
      {/* Forgot Password Dialog Box */}
      <Modal
        show={showDialogBox}
        onHide={handleCloseDialog}
        centered
        className="modalStyleSignin"
      >
        <Modal.Header className="model_header">
          <div className="header-content">
            <div>
              <Modal.Title>Forgot Password</Modal.Title>
              <p className="modalText">
                Enter your registered Email to get a new password
              </p>
            </div>
            <div className="close-icon" onClick={handleCloseDialog}>
              <img src={cancel} alt="Close" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="dialog-body">
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="email_text_dialog">Email</Form.Label>
              <Form.Control
                className="email_field_dialog"
                type="email"
                name=""
                id=""
                placeholder="Enter your email"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="model_footer">
          <Button className="send_btn" onClick={handleSendButton}>
            Send
          </Button>
          <Link className="back_to_login" onClick={handleCloseDialog}>
            Back to Login
          </Link>
        </Modal.Footer>
      </Modal>

      {/* Successfully Reset Message Dialog Box */}
      <Modal show={showResetMsg} centered className="modalStyleSignin">
        <Modal.Body className="dialog-body">
          <div className="submit-icon">
            <img src={submitIcon} alt="Ok" />
          </div>
          <h3 className="pswrd-sent-text">Password Sent</h3>
          <p className="pswrd-sent-content">
            Your new password has been sent to the registered email ID.
          </p>
        </Modal.Body>
        <Modal.Footer className="model_footer">
          <Button
            className="ok_btn"
            onClick={() => {
              setShowResetMsg(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SignIn;
