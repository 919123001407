import React, { useRef, useState } from "react";
import { Card, Overlay, Table } from "react-bootstrap";
import "../styles/horiBarChart.css";
import "../styles/vertBarChart.css";
import "../styles/pieChart.css";
import "../styles/dashboardView.css";
import settingIcon from "../Icons/settingIcon.svg";

import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

export const DashboardView = ({
  checkedItems,
  handleCheckboxChange,
  handleUpdateClick,
  show,
  setShow,
}) => {
  const target = useRef(null);

  return (
    <div className="top_sec">
      <span className="dashboard_text">Dashboard</span>
      <img
        src={settingIcon}
        alt=""
        ref={target}
        onClick={() => setShow(!show)}
        style={{ cursor: "pointer" }}
      />
      <Overlay target={target.current} show={show} placement="bottom">
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              backgroundColor: "white",
              padding: "10px",
              color: "black",
              borderRadius: "8px",
              width: "324px",
              height: "auto",
              border: "1px solid #c5c5c5",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              marginTop: "10px",
              ...props.style,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <label
                style={{
                  display: "flex",
                  color: "#0a0a0a",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  lineHeight: "16.34px",
                  fontWeight: "400",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    marginRight: "8px",
                    width: "20px",
                    height: "20px",
                    border: "none",
                  }}
                  name="cloudAccountDetails"
                  checked={checkedItems.cloudAccountDetails}
                  onChange={handleCheckboxChange}
                />
                Cloud Account details
              </label>
              <label
                style={{
                  display: "flex",
                  color: "#0a0a0a",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  lineHeight: "16.34px",
                  fontWeight: "400",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    marginRight: "8px",
                    width: "20px",
                    height: "20px",
                    border: "none",
                  }}
                  name="overallCloudComplianceStatus"
                  checked={checkedItems.overallCloudComplianceStatus}
                  onChange={handleCheckboxChange}
                />
                Overall Cloud Compliance Status
              </label>
              <label
                style={{
                  display: "flex",
                  color: "#0a0a0a",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  lineHeight: "16.34px",
                  fontWeight: "400",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    marginRight: "8px",
                    width: "31px",
                    height: "31px",
                    border: "none",
                  }}
                  name="comprehensiveOverview"
                  checked={checkedItems.comprehensiveOverview}
                  onChange={handleCheckboxChange}
                />
                Comprehensive Overview of Incidents, Vulnerabilities, and
                Control Failures
              </label>
              <label
                style={{
                  display: "flex",
                  color: "#0a0a0a",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  lineHeight: "16.34px",
                  fontWeight: "400",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    marginRight: "8px",
                    width: "20px",
                    height: "20px",
                    border: "none",
                  }}
                  name="cloudAccountRegulatoryStatus"
                  checked={checkedItems.cloudAccountRegulatoryStatus}
                  onChange={handleCheckboxChange}
                />
                Cloud Account(s) Regulatory compliance status
              </label>
              <label
                style={{
                  display: "flex",
                  color: "#0a0a0a",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  lineHeight: "16.34px",
                  fontWeight: "400",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    marginRight: "8px",
                    width: "20px",
                    height: "20px",
                    border: "none",
                  }}
                  name="buWiseRegulatoryStatus"
                  checked={checkedItems.buWiseRegulatoryStatus}
                  onChange={handleCheckboxChange}
                />
                BU-wise Regulatory Cloud Compliance Status
              </label>
              <label
                style={{
                  display: "flex",
                  color: "#0a0a0a",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  lineHeight: "16.34px",
                  fontWeight: "400",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    marginRight: "8px",
                    width: "20px",
                    height: "20px",
                    border: "none",
                  }}
                  name="buWiseCloudStatus"
                  checked={checkedItems.buWiseCloudStatus}
                  onChange={handleCheckboxChange}
                />
                BU-wise Cloud Compliance status
              </label>
              <label
                style={{
                  display: "flex",
                  color: "#0a0a0a",
                  fontFamily: "Noto Sans",
                  fontSize: "12px",
                  lineHeight: "16.34px",
                  fontWeight: "400",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    marginRight: "8px",
                    width: "20px",
                    height: "20px",
                    border: "none",
                  }}
                  name="applicationWiseRegulatoryStatus"
                  checked={checkedItems.applicationWiseRegulatoryStatus}
                  onChange={handleCheckboxChange}
                />
                Application-wise Regulatory compliance status
              </label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                style={{
                  width: "252px",
                  height: "36px",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#2787e4",
                  color: "#ffffff",
                  border: "none",
                  fontFamily: "Noto Sans",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "21.79px",
                }}
                onClick={handleUpdateClick}
              >
                Update
              </button>
            </div>
          </div>
        )}
      </Overlay>
    </div>
  );
};

export const PieChart = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: ["AWS", "GCP", "Azure"],

    datasets: [
      {
        // label: "My First Dataset",
        data: [100, 30, 30],
        backgroundColor: ["#355493", "#3498B3", "#746fa7"],
        hoverOffset: 5,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 20,
        },
      },
    },
  };

  return (
    <div>
      <Card className="pieChartCard shadowCard">
        <Card.Body>
          <Card.Title className="cloud_Acc_detail">
            Cloud Account details
          </Card.Title>
          <br />

          <Card.Text
            style={{
              width: "260px",
            }}
          >
            <Doughnut data={data} options={options} />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};
export const HoriBarChart = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const data = {
    labels: ["AWS", "Azure", "GCP"],
    datasets: [
      {
        label: "Compliant",
        data: [10, 20, 30], // Replace with your data
        backgroundColor: "#355493",
      },

      {
        label: "Non-Compliant",
        data: [5, 15, 25], // Replace with your data
        backgroundColor: "#3498B3",
      },
    ],
  };
  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false, // Allow chart to fill container height
    plugins: {
      legend: {
        position: "bottom", // Move legend to the bottom
      },
      title: {
        display: true,
        // text: "Compliance Status of Cloud Providers",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        barThickness: 50, // Increased bar thickness for taller bars
        maxBarThickness: 60, // Set maximum bar thickness
        categoryPercentage: 0.8, // Adjusted gap between bars
      },
    },
  };

  return (
    <div>
      <Card className="horiBarCard shadowCard">
        <Card.Body>
          <Card.Title className="cloud_comp_status">
            Overall Cloud Compliance Status
          </Card.Title>
          <Card.Text style={{ height: "270px" }}>
            <Bar data={data} options={options} />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};
export const VertBarChart = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const data = {
    labels: ["Metrics"],
    datasets: [
      {
        label: "No. of Open Incidents",
        data: [100], // Replace with your data
        backgroundColor: "#746FA7",
      },
      {
        label: "No. of Vulnerabilities",
        data: [200], // Replace with your data
        backgroundColor: "#355493",
      },
      {
        label: "No. of Control Failures",
        data: [150], // Replace with your data
        backgroundColor: "#3498B3",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            size: 10,
          },
          boxWidth: 20,
        },
        align: "center",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        barThickness: 10, // Set a fixed bar thickness
        categoryPercentage: 0.5, // Adjust this value to increase/decrease space between bars
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div>
      <Card className="vertBarCard shadowCard">
        <Card.Body>
          <Card.Title className="overview_of_incidents">
            Comprehensive Overview of Incidents, Vulnerabilities, and Control
            Failures
          </Card.Title>
          <Card.Text style={{ height: "230px" }}>
            <Bar data={data} options={options} />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};
export const CloudAccoutsComplainceTable = ({ dummyData }) => {
  return (
    <>
      <div className="d-flex justify-content-center dashboard-table-title">
        Cloud Account(s) Regulatory compliance status
      </div>
      <Table>
        <thead>
          <tr>
            {Object.keys(dummyData.CloutAccoutsComplainceData[0]).map(
              (header) => (
                <th className="dashboardView-table-header" key={header}>
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {dummyData.CloutAccoutsComplainceData.map((items) => (
            <tr key={items["Cloud Account"]}>
              <td>{items["CSP"]}</td>
              <td>{items["Cloud Account"]}</td>
              {valueFormatter(items["ISO 27001: 2022"])}
              {valueFormatter(items["PCI DSS 4.0"])}
              {valueFormatter(items["NIST 800-53 R5"])}
              {valueFormatter(items["HIPAA"])}
              {valueFormatter(items["GDPR"])}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export const BuWiseRegulatoryCloudComplianceStatus = ({ dummyData }) => {
  return (
    <>
      <div className="d-flex justify-content-center dashboard-table-title">
        BU-wise Regulatory Cloud Compliance Status
      </div>
      <Table>
        <thead>
          <tr>
            {Object.keys(dummyData.BuWiseRegulatoryCloudComplianceData[0]).map(
              (header) => (
                <th className="dashboardView-table-header" key={header}>
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {dummyData.BuWiseRegulatoryCloudComplianceData.map((items) => (
            <tr key={items["BU Name"]}>
              <td className="td_items">{items["BU Name"]}</td>
              {valueFormatter(items["ISO 27001: 2022"])}
              {valueFormatter(items["PCI DSS 4.0"])}
              {valueFormatter(items["NIST 800-53 R5"])}
              {valueFormatter(items["HIPAA"])}
              {valueFormatter(items["GDPR"])}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export const BuWiseCloudComplianceStatus = ({ dummyData }) => {
  return (
    <>
      <div className="d-flex justify-content-center dashboard-table-title">
        BU-wise Cloud Compliance status
      </div>
      <Table>
        <thead>
          <tr>
            {Object.keys(dummyData.BuWiseCloudComplianceData[0]).map(
              (header) => (
                <th className="dashboardView-table-header" key={header}>
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {dummyData.BuWiseCloudComplianceData.map((items) => (
            <tr key={items["BU Name"]}>
              <td className="td_items">{items["BU Name"]}</td>
              <td className="td_items">{items["CSP"]}</td>
              <td className="td_items">{items["Cloud Account"]}</td>
              {valueFormatter(items["ISO 27001: 2022"])}
              {valueFormatter(items["PCI DSS 4.0"])}
              {valueFormatter(items["NIST 800-53 R5"])}
              {valueFormatter(items["HIPAA"])}
              {valueFormatter(items["GDPR"])}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export const ApplicationWiseRegulatoryComplianceStatus = ({ dummyData }) => {
  return (
    <>
      <div className="d-flex justify-content-center dashboard-table-title">
        Application-wise Regulatory compliance status
      </div>
      <Table>
        <thead>
          <tr>
            {Object.keys(
              dummyData.ApplicationWiseRegulatoryComplianceData[0]
            ).map((header) => (
              <th className="dashboardView-table-header" key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dummyData.ApplicationWiseRegulatoryComplianceData.map((items) => (
            <tr key={items["Application Name"]}>
              <td className="td_items">{items["Application Name"]}</td>
              <td className="td_items">{items["BU Name"]}</td>
              {valueFormatter(items["ISO 27001: 2022"])}
              {valueFormatter(items["PCI DSS 4.0"])}
              {valueFormatter(items["NIST 800-53 R5"])}
              {valueFormatter(items["HIPAA"])}
              {valueFormatter(items["GDPR"])}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
const valueFormatter = (value) => {
  const customClassName =
    value?.slice(0, -1) == 100
      ? "green"
      : value?.slice(0, -1) >= 90
      ? "amber"
      : "red";
  return <td className={customClassName}>{value}</td>;
};
