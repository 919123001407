import React, { useState } from "react";
import "../styles/landingPageVC.css";
import RSimage from "../Images/RSimage.svg";
import WiproLogo from "../Images/WiproLogo.svg";
import { Row, Col, Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import submitIcon from "../Icons/submitIcon.svg";
import { useNavigate } from "react-router";
import axios from "axios";

const LandingPageVC = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    const newCode = e.target.value;
    setCode(newCode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("email", email);
    console.log("code", code);
    try {
      const response = await axios.post(
        "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/user/verifycode",
        {
          organisationalemailid: email,
          confirmation_code: code,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response", response);
      console.log("response data", response.data);
      if (response.data.statusCode === 200) {
        setShowConfirmMsg(true);
      } else if (response.data.statusCode === 400) {
        setIsValid(false);
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };
  const handleOk = () => {
    setShowConfirmMsg(false);
    navigate("/setpassword", { state: { email } });
  };

  return (
    // handling responsive
    <div>
      <Row>
        <Col md={7} className="left">
          <div className="left_image_container">
            <img src={WiproLogo} alt="" />
            <p className="CCS_text">Cloud Compliance Shield</p>
          </div>
          <div className="text_container_vc">
            <p className="verification_code">Verification Required</p>
          </div>

          <div className="box_Container_vc">
            <div className="email_container_vc">
              <label className="email_text" htmlFor="">
                Email
                <span className="astrick">*</span>
              </label>
              <br />
              <input
                className="email_field"
                type="email"
                value={email}
                name=""
                id=""
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
              />
              <br />
            </div>
            <div className="verification_code_container">
              <label className="verification_code_text" htmlFor="">
                Enter verification code
                <span className="astrick">*</span>
              </label>
              <br />
              <input
                className="verification_code_container_field"
                type="text"
                value={code}
                onChange={handleChange}
                autoComplete="off"
              />
              {!isValid && (
                <p
                  style={{
                    fontFamily: "Noto Sans",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#FF3B30",
                    marginBottom: "2px",
                  }}
                >
                  Please enter a valid verification code.
                </p>
              )}
            </div>
            <div>
              <button className="confirm_btn_vc">
                <p className="confirm_btn_vc_text" onClick={handleSubmit}>
                  Confirm
                </p>
              </button>
            </div>
          </div>
        </Col>
        <Col className="right text-center" md={5}>
          <span className="right_box_text ">
            AI-Driven Insights for Seamless Compliance <br /> And Security
            Across Multi-Cloud Ecosystems.
          </span>
          <div className="right_img my-5">
            <img className="rs_1 " src={RSimage} alt="" />
          </div>
        </Col>
      </Row>
      <Modal show={showConfirmMsg} centered className="modalStyle-vc">
        <Modal.Body className="dialog-body">
          <div className="submit-icon">
            <img src={submitIcon} alt="Ok" />
          </div>
          <h3 className="verification-text">Verification Successful</h3>
          <p className="verification-content">
            Verification has been done successfully.
          </p>
        </Modal.Body>
        <Modal.Footer className="model_footer">
          <Button className="ok_btn" onClick={() => handleOk()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LandingPageVC;
