import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer">Powered By AWS</div>
    </>
  );
};

export default Footer;
