import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import iconOpen from "../Icons/iconOpen.svg";
import iconClose from "../Icons/iconClose.svg";
import "../styles/cloudAccount.css";
import "../styles/cloudAccountTable.css";
import { Dropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../styles/cloudAccountTable.css";
import Edit from "../Icons/edit.svg";
import Delete from "../Icons/delete.svg";
import tooltip from "../Icons/tooltip.svg";
import submitIcon from "../Icons/submitIcon.svg";
import cancel from "../Icons/cancel.svg";
import searchIcon from "../Icons/searchIcon.svg";
import rejectIcon from "../Icons/reject.svg";

import axios from "axios";

export const CloudAccounts = ({
  editAWS,
  setEditAWS,
  editAZURE,
  setEditAZURE,
  editGCP,
  setEditGCP,
  setUniqueFormData,
  uniqueFormData,
  refreshTableData,
  tableData,
  setTableData,
  onSaveSuccess,
  selectedAccount,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedOptionBU, setSelectedOptionBU] = useState(null);
  const [selectedBusinessUnitId, setSelectedBusinessUnitId] = useState(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEditAWS, setModalShowEditAWS] = useState(false);
  const [error, setError] = useState("");
  const [errorEdit, setErrorEdit] = useState("");

  const [formData, setFormData] = useState({
    cloudtypeid: 1,
    accountownername: "",
    accountid: "",
    accountowneremailid: "",
    secretkey: "",
    accesskey: "",
  });
  const handleCanceldropdown = () => {
    setSelectedOption(null);
    setIsOpen(false);
  };
  const handleOkBtn = () => {
    setModalShow(false);
    setIsOpen(false);
    setSelectedOption(null);
  };
  const handleOkBtnEditAWS = () => {
    setModalShowEditAWS(false);
  };
  const handleCSPChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError("");
  };
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setUniqueFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectBuEdit = (value) => {
    handleEditChange({ target: { name: "businessname", value } });
  };

  const handleSelectBU = (eventKey) => {
    const selectedUnit = businessUnits.find(
      (unit) => unit[0].toString() === eventKey
    );
    if (selectedUnit) {
      setSelectedOptionBU(selectedUnit[1]);
      setSelectedBusinessUnitId(String(selectedUnit[0]));
    }
  };

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/businessunit"
        );
        if (response.status === 200) {
          const { body } = response.data; // Extract the body field from the response
          if (Array.isArray(body)) {
            setBusinessUnits(body); // Set the body array as the business units
          } else {
            console.error("Body is not an array:", body);
          }
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };
    fetchBusinessUnits();
  }, []);

  const validateForm = () => {
    if (
      !formData.accesskey ||
      !formData.secretkey ||
      !formData.accountid ||
      !formData.accountownername ||
      !formData.accountowneremailid
    ) {
      setError("Form Incomplete. Please provide all mandatory information.");
      return false;
    }
    setError("");
    return true;
  };

  const checkValidation = async (
    accesskey,
    secretkey,
    accountid,
    accountowneremailid
  ) => {
    console.log(
      "access Key, secret key, account Id , email id",
      accesskey,
      secretkey,
      accountid,
      accountowneremailid
    );

    try {
      const jsonData = JSON.stringify({
        accesskey,
        secretkey,
        accountid,
        accountowneremailid,
      });

      const response = await fetch(
        "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/cloudaccounts/validation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonData,
        }
      );
      const result = await response.json();
      const parsedBody = JSON.parse(result.body);
      console.log("Parsebody", parsedBody);
      if (parsedBody.message) {
        console.log("parseBody.message", parsedBody.message);
        return { isValid: true, message: parsedBody.message };
      } else if (parsedBody.error) {
        console.log("parseBody.error", parsedBody.error);
        return { isValid: false, error: parsedBody.error };
      }
    } catch (error) {
      console.error("Validation error:", error);
      return {
        isValid: false,
        error: "An unexpected error occurred during validation.",
      };
    }
  };

  const checkValidationEdit = async (
    accesskey,
    secretkey,
    accountid,
    accountowneremailid
  ) => {
    console.log(
      "access Key, secret key, account Id , email id",
      accesskey,
      secretkey,
      accountid,
      accountowneremailid
    );

    try {
      const jsonData = JSON.stringify({
        accesskey,
        secretkey,
        accountid,
        accountowneremailid,
      });

      const response = await fetch(
        "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/cloudaccounts/validation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonData,
        }
      );
      const result = await response.json();
      const parsedBody = JSON.parse(result.body);
      console.log("Parsebody", parsedBody);
      if (parsedBody.message) {
        console.log("parseBody.message", parsedBody.message);
        return { isValid: true, message: parsedBody.message };
      } else if (parsedBody.error) {
        console.log("parseBody.error", parsedBody.error);
        return { isValid: false, error: parsedBody.error };
      }
    } catch (error) {
      console.error("Validation error:", error);
      return {
        isValid: false,
        error: "An unexpected error occurred during validation.",
      };
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const dataToSave = { ...formData, businessunitid: selectedBusinessUnitId };
    const jsonData = JSON.stringify(dataToSave);
    const { accesskey, secretkey, accountid, accountowneremailid } = formData;

    if (validateForm()) {
      const dataPesent = await checkValidation(
        accesskey,
        secretkey,
        accountid,
        accountowneremailid
      );

      if (dataPesent.isValid) {
        try {
          const response = await fetch(
            "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/cloudaccounts",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: jsonData,
            }
          );
          if (response.ok) {
            // Handle success response
            console.log(response);
            const result = await response.json();
            if (result.statusCode === 200) {
              console.log(result);
              const responseBody = JSON.parse(result.body);
              const eventFields = responseBody.data;
              console.log(eventFields);
              refreshTableData();
              setFormData({
                cloudtypeid: 1,
                accountownername: "",
                accountid: "",
                accountowneremailid: "",
                secretkey: "",
                accesskey: "",
                selectedOptionBU: "",
              });
              setSelectedOptionBU(null);
            }
          } else {
            // Handle error response
            console.error("Failed to save data");
          }
          setEditAWS(false);
        } catch (error) {
          console.error("Error:", error);
        }
        setModalShow(true);
      } else {
        setError("Invalid AWS account credential");
      }
    }

    console.log("data saved");
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    // const email = uniqueFormData.accountowneremailid;
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@wipro\.com$/;
    // if (!emailPattern.test(email)) {
    //   setModalShowEditAWS(true);
    //   return;
    // }
    // Convert form data to JSON format

    const { accesskey, secretkey, accountid, accountowneremailid } =
      uniqueFormData;

    const dataPesent = await checkValidationEdit(
      accesskey,
      secretkey,
      accountid,
      accountowneremailid
    );

    if (dataPesent.isValid) {
      const jsonData = JSON.stringify(uniqueFormData);
      console.log(jsonData);
      console.log("data saved");
      try {
        const response = await fetch(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/cloudaccounts",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: jsonData,
          }
        );
        if (response.ok) {
          // Handle success response
          console.log("response is ok", response);
          const result = await response.json();
          if (result.statusCode === 200) {
            console.log("response is json", result);
            const responseBody = JSON.parse(result.body);
            console.log("response Body for edit field", responseBody);
            const eventFields = responseBody.data;
            // setTableData(eventFields);
            setTableData(eventFields);
            refreshTableData();
            console.log("TableData from EDIT API", tableData);
            console.log("Event Field from EDIT API ", eventFields);
          }
        }
        setEditAWS(false);
        setErrorEdit(false);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      // setErrorEdit("Invalid AWS account credential");
      setModalShowEditAWS(true);
    }
  };
  return (
    <Container>
      <Row>
        <Col>
          <div>
            <div className={isOpen ? "mb-3" : ""}>
              {editAWS ? (
                <div>
                  <span className="add_account">Edit the AWS page</span>
                  <div
                    style={{
                      border: "1px solid #C5c5c5",
                      borderRadius: "16px",
                      padding: "20px",
                    }}
                    className="mt-3"
                  >
                    <Row>
                      <Col className="form_group">
                        <label htmlFor="" className="cloud_service_text">
                          Cloud Service Provider
                          <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="dropdown_cloudAccountTable"
                          >
                            <span>AWS</span>
                          </Dropdown.Toggle>
                        </Dropdown>
                      </Col>
                      <Col>
                        <label htmlFor="">
                          Account ID <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        <input
                          type="text"
                          className="inputtext_aws"
                          value={uniqueFormData.accountid}
                          onChange={handleEditChange}
                          name="accountid"
                          disabled
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Account Owner Name<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <input
                          type="text"
                          className="inputtext_aws"
                          value={uniqueFormData.accountownername}
                          onChange={handleEditChange}
                          name="accountownername"
                          autoComplete="off"
                          disabled
                        />
                      </Col>
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Account Owner Email ID
                          <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        <input
                          type="text"
                          className="inputtext_aws"
                          value={uniqueFormData.accountowneremailid}
                          onChange={handleEditChange}
                          autoComplete="off"
                          name="accountowneremailid"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Access Key<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <input
                          type="password"
                          className="inputtext_aws"
                          value={uniqueFormData.accesskey}
                          name="accesskey"
                          autoComplete="off"
                          onChange={handleEditChange}
                        />
                      </Col>
                      <Col>
                        <label htmlFor="">
                          Secret Key<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          type="password"
                          className="inputtext_aws"
                          value={uniqueFormData.secretkey}
                          name="secretkey"
                          autoComplete="off"
                          onChange={handleEditChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className="form_group">
                        <label
                          htmlFor="businessname"
                          className="acc_owner_text"
                        >
                          BU Name<span className="astrick">*</span>
                        </label>
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp;&nbsp;
                        <Dropdown onSelect={handleSelectBuEdit}>
                          <Dropdown.Toggle className="dropdown_field_aws_bu">
                            {uniqueFormData.businessname || "Select BU Name"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown_menu_aws_bu">
                            {businessUnits
                              .sort((a, b) => a[1].localeCompare(b[1]))
                              .map((option, index) => (
                                <Dropdown.Item
                                  key={index}
                                  eventKey={option[1]}
                                  className="dropdown_item_aws_bu"
                                >
                                  {option[1]}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col></Col>
                    </Row>
                    <br />
                    <br />
                    {errorEdit && (
                      <span
                        style={{
                          color: "red",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {errorEdit}
                      </span>
                    )}
                    <br />
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Button
                          onClick={handleEdit}
                          style={{
                            width: "84px",
                            height: "48px",
                            borderRadius: "8px",
                            backgroundColor: "#2787e4",
                            border: "none",
                            font: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "21.79px",
                          }}
                          className="mx-3"
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            setEditAWS(false);
                          }}
                          style={{
                            width: "99px",
                            height: "48px",
                            borderRadius: "8px",
                            backgroundColor: "#ff2929",
                            border: "none",
                            font: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "21.79px",
                          }}
                        >
                          Cancel
                        </Button>
                        <Modal
                          show={modalShowEditAWS}
                          centered
                          className="modalStyle-msg-cloud-account-edit"
                        >
                          <Modal.Body className="dialog-body">
                            <div className="submit-icon-integration">
                              <img
                                src={rejectIcon}
                                alt="Ok"
                                style={{ height: "80px", marginTop: "15px" }}
                              />
                            </div>
                            <div className="content">
                              <h3 className="integration-tool-text1">
                                Incorrect
                              </h3>
                              <p className="integration-tool-text2">
                                The credentials you entered are incorrect.
                                Please double-check and enter the correct
                                details to proceed.
                              </p>
                            </div>
                          </Modal.Body>
                          <Modal.Footer className="model_footer_integration">
                            <Button
                              className="ok_btn"
                              onClick={handleOkBtnEditAWS}
                            >
                              Ok
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : editAZURE ? (
                <div>
                  <span className="add_account">Edit the Azure page</span>
                  <div
                    style={{
                      border: "1px solid #C5c5c5",
                      borderRadius: "16px",
                      padding: "20px",
                    }}
                    className="mt-3"
                  >
                    <Row>
                      <Col className="form_group">
                        <label htmlFor="" className="cloud_service_text">
                          Cloud Service Provider
                          <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="dropdown_cloudAccountTable"
                          >
                            <span>AZURE</span>
                          </Dropdown.Toggle>
                        </Dropdown>
                      </Col>
                      <Col>
                        <label htmlFor="">
                          Subscription ID <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Account Owner Name<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Tenant ID
                          <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Account Owner Email ID
                          <span className="astrick">*</span>
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                      <Col>
                        <label htmlFor="">
                          Subcription Name<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Client ID<span className="astrick">*</span>
                        </label>
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Client Secret<span className="astrick">*</span>
                        </label>
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          BU Name<span className="astrick">*</span>
                        </label>
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                      <Col></Col>
                    </Row>
                    <br />
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Button
                          onClick={() => {
                            setEditAZURE(false);
                          }}
                          style={{
                            width: "84px",
                            height: "48px",
                            borderRadius: "8px",
                            backgroundColor: "#2787e4",
                            border: "none",
                            font: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "21.79px",
                          }}
                          className="mx-3"
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            setEditAZURE(false);
                          }}
                          style={{
                            width: "99px",
                            height: "48px",
                            borderRadius: "8px",
                            backgroundColor: "#ff2929",
                            border: "none",
                            font: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "21.79px",
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : editGCP ? (
                <div>
                  <span className="add_account">Edit the GCP page</span>
                  <div
                    style={{
                      border: "1px solid #C5c5c5",
                      borderRadius: "16px",
                      padding: "20px",
                    }}
                    className="mt-3"
                  >
                    <Row>
                      <Col className="form_group">
                        <label htmlFor="" className="cloud_service_text">
                          Cloud Service Provider
                          <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="dropdown_cloudAccountTable"
                          >
                            <span>GCP</span>
                          </Dropdown.Toggle>
                        </Dropdown>
                      </Col>
                      <Col>
                        <label htmlFor="">
                          Project ID <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Account Owner Name*<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                      <Col>
                        <label htmlFor="" className="acc_owner_text">
                          Account Owner Email ID
                          <span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className="form_group">
                        <label htmlFor="" className="acc_owner_text">
                          BU Name<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="dropdown_cloudAccountTable"
                          >
                            <span>CIS</span>
                          </Dropdown.Toggle>
                        </Dropdown>
                      </Col>
                      <Col>
                        <label htmlFor="">
                          Project Name<span className="astrick">*</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp;
                        <input type="text" className="inputtext_aws" />
                      </Col>
                    </Row>

                    <br />
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Button
                          onClick={() => {
                            setEditGCP(false);
                          }}
                          style={{
                            width: "84px",
                            height: "48px",
                            borderRadius: "8px",
                            backgroundColor: "#2787e4",
                            border: "none",
                            font: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "21.79px",
                          }}
                          className="mx-3"
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            setEditGCP(false);
                          }}
                          style={{
                            width: "99px",
                            height: "48px",
                            borderRadius: "8px",
                            backgroundColor: "#ff2929",
                            border: "none",
                            font: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "21.79px",
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                <span className="add_account">Add Account</span>
              )}
              {editAWS || editAZURE || editGCP ? (
                ""
              ) : (
                <button onClick={toggleDropdown} className="dropdown_btn">
                  {isOpen ? (
                    <img src={iconOpen} alt="" />
                  ) : (
                    <img src={iconClose} alt="" />
                  )}
                </button>
              )}
            </div>
            {isOpen && (
              <div className="cloud_service_container">
                {selectedOption === "AWS" ? (
                  <div>
                    <Form>
                      <Row>
                        <Col className="form_group">
                          <label htmlFor="" className="cloud_service_text">
                            Cloud Service Provider
                            <span className="astrick">*</span>
                          </label>
                          &nbsp;
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              className="dropdown_aws mx-4"
                              onChange={handleCSPChange}
                              value={dataState.cloudServiceProvider}
                              name="cloudServiceProvider"
                            >
                              <span>AWS</span>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </Col>
                        <Col>
                          <label htmlFor="" className="acc_owner_text">
                            Account ID<span className="astrick">*</span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            name="accountid"
                            type="text"
                            className="inputtext_aws"
                            onChange={handleCSPChange}
                            value={dataState.accountid}
                            autoComplete="off"
                            required
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <label htmlFor="" className="acc_owner_text">
                            Account owner name<span className="astrick">*</span>
                          </label>
                          <input
                            name="accountownername"
                            type="text"
                            className="inputtext_aws"
                            onChange={handleCSPChange}
                            value={formData.accountownername}
                            autoComplete="off"
                            required
                          />
                        </Col>
                        <Col>
                          <label htmlFor="" className="acc_owner_text">
                            Account owner email ID
                            <span className="astrick">*</span>
                          </label>
                          <input
                            name="accountowneremailid"
                            type="text"
                            className="inputtext_aws"
                            onChange={handleCSPChange}
                            value={formData.accountowneremailid}
                            autoComplete="off"
                            required
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <label htmlFor="" className="acc_owner_text">
                            Access Key<span className="astrick">*</span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            name="accesskey"
                            type="password"
                            className="inputtext_aws"
                            onChange={handleCSPChange}
                            value={formData.accesskey}
                            autoComplete="off"
                            required
                          />
                        </Col>
                        <Col>
                          <label htmlFor="" className="acc_owner_text">
                            Secret Key<span className="astrick">*</span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            name="secretkey"
                            type="password"
                            className="inputtext_aws"
                            onChange={handleCSPChange}
                            value={formData.secretkey}
                            autoComplete="off"
                            required
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col className="form_group">
                          <label htmlFor="" className="acc_owner_text">
                            Business Unit<span className="astrick">*</span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Dropdown onSelect={handleSelectBU}>
                            <Dropdown.Toggle className="dropdown_field_aws_bu">
                              {selectedOptionBU || "Select Business Unit"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              className="dropdown_menu_aws_bu"
                              name="businessunitid"
                            >
                              {businessUnits
                                .sort((a, b) => a[1].localeCompare(b[1]))
                                .map((unit) => (
                                  <Dropdown.Item
                                    key={unit[0]}
                                    eventKey={unit[0].toString()}
                                    className="dropdown_item_aws_bu"
                                  >
                                    {unit[1]}
                                    {/* Displays Business Unit Name and Description */}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                      <br />
                      {error && (
                        <span
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {error}
                        </span>
                      )}
                      <br />
                      <Row>
                        <Col className="d-flex justify-content-center">
                          <Button
                            onClick={handleSave}
                            style={{
                              width: "84px",
                              height: "48px",
                              borderRadius: "8px",
                              backgroundColor: "#2787e4",
                              border: "none",
                              font: "Noto Sans",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "21.79px",
                            }}
                            className="mx-3"
                          >
                            Save
                          </Button>
                          <Button
                            onClick={handleCanceldropdown}
                            style={{
                              width: "99px",
                              height: "48px",
                              borderRadius: "8px",
                              backgroundColor: "#ff2929",
                              border: "none",
                              font: "Noto Sans",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "21.79px",
                            }}
                          >
                            Cancel
                          </Button>
                          <Modal
                            show={modalShowEditAWS}
                            centered
                            className="modalStyle-msg-cloud-account-edit"
                          >
                            <Modal.Body className="dialog-body">
                              <div className="submit-icon-integration">
                                <img
                                  src={rejectIcon}
                                  alt="Ok"
                                  style={{ height: "80px", marginTop: "15px" }}
                                />
                              </div>
                              <div className="content">
                                <h3 className="integration-tool-text1">
                                  Incorrect
                                </h3>
                                <p className="integration-tool-text2">
                                  The credentials you entered are incorrect.
                                  Please double-check and enter the correct
                                  details to proceed.
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer className="model_footer_integration">
                              <Button
                                className="ok_btn"
                                onClick={handleOkBtnEditAWS}
                              >
                                Ok
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          <Modal
                            show={modalShow}
                            centered
                            className="modalStyle-msg-integration"
                          >
                            <Modal.Body className="dialog-body">
                              <div className="submit-icon-integration">
                                <img src={submitIcon} alt="Ok" />
                              </div>
                              <div className="content">
                                <h3 className="integration-tool-text1">
                                  Cloud Account Added
                                </h3>
                                <p className="integration-tool-text2">
                                  The user has been added successfully
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer className="model_footer_integration">
                              <Button className="ok_btn" onClick={handleOkBtn}>
                                Ok
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                ) : selectedOption === "Azure" ? (
                  <AzureForm />
                ) : selectedOption === "GCP" ? (
                  <GcpForm />
                ) : (
                  <div className="form_group">
                    <label className="cloud_service_text">
                      Cloud Service Provider<span className="astrick">*</span>
                    </label>

                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle
                        variant="success"
                        className="dropdown_field_cloud mx-3"
                      >
                        <span>Choose cloud type</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown_menu_cloud">
                        <Dropdown.Item
                          className="dropdown_item_cloud"
                          eventKey="AWS"
                        >
                          AWS
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown_item_cloud"
                          eventKey="Azure"
                        >
                          Azure
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown_item_cloud"
                          eventKey="GCP"
                        >
                          GCP
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const AzureForm = () => (
  <Container>
    <Row>
      <Col className="form_group">
        <label htmlFor="" className="cloud_service_text">
          Cloud Service Provider<span className="astrick">*</span>
        </label>
        &nbsp;&nbsp;&nbsp; &nbsp;
        <Dropdown>
          <Dropdown.Toggle variant="success" className="dropdown_azure mx-4">
            <span>Azure</span>
          </Dropdown.Toggle>
        </Dropdown>
      </Col>
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Subscription ID<span className="astrick">*</span>
        </label>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <input type="text" className="inputtext_aws " />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Account owner name<span className="astrick">*</span>
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <input type="text" className="inputtext_aws" />
      </Col>
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Tenant ID<span className="astrick">*</span>
        </label>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="text" className="inputtext_aws" />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Account Owner Email ID<span className="astrick">*</span>
        </label>

        <input type="text" className="inputtext_aws" />
      </Col>
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Subscription Name<span className="astrick">*</span>
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="text" className="inputtext_aws" />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Client ID<span className="astrick">*</span>
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;
        <input type="text" className="inputtext_aws" />
      </Col>
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Client Secret<span className="astrick">*</span>
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="text" className="inputtext_aws" />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col className="d-flex justify-content-center">
        <Button
          style={{
            width: "84px",
            height: "48px",
            borderRadius: "8px",
            backgroundColor: "#2787e4",
            border: "none",
            font: "Noto Sans",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "21.79px",
          }}
          className="mx-3"
        >
          Save
        </Button>
        <Button
          style={{
            width: "99px",
            height: "48px",
            borderRadius: "8px",
            backgroundColor: "#ff2929",
            border: "none",
            font: "Noto Sans",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "21.79px",
          }}
        >
          Cancel
        </Button>
      </Col>
    </Row>
  </Container>
);
const GcpForm = () => (
  <Container>
    <Row>
      <Col className="form_group">
        <label htmlFor="" className="cloud_service_text">
          Cloud Service Provider<span className="astrick">*</span>
        </label>
        &nbsp;
        <Dropdown>
          <Dropdown.Toggle variant="success" className="dropdown_gcp mx-4">
            <span>GCP</span>
          </Dropdown.Toggle>
        </Dropdown>
      </Col>
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Project ID<span className="astrick">*</span>
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="text" className="inputtext_aws" />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Account owner name<span className="astrick">*</span>
        </label>
        <input type="text" className="inputtext_aws" />
      </Col>
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Account owner email ID<span className="astrick">*</span>
        </label>

        <input type="text" className="inputtext_aws" />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Project name<span className="astrick">*</span>
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="text" className="inputtext_aws" />
      </Col>
      <Col>
        <label htmlFor="" className="acc_owner_text">
          Credential File<span className="astrick">*</span>
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          style={{
            width: "102px",
            height: "42px",
            backgroundColor: "#2787e4",
            borderRadius: "12px",
            border: "none",
            font: "Noto Sans",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "21.79px",
          }}
        >
          Upload
        </Button>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col className="d-flex justify-content-center">
        <Button
          style={{
            width: "84px",
            height: "48px",
            borderRadius: "8px",
            backgroundColor: "#2787e4",
            border: "none",
            font: "Noto Sans",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "21.79px",
          }}
          className="mx-3"
        >
          Save
        </Button>
        <Button
          style={{
            width: "99px",
            height: "48px",
            borderRadius: "8px",
            backgroundColor: "#ff2929",
            border: "none",
            font: "Noto Sans",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "21.79px",
          }}
        >
          Cancel
        </Button>
      </Col>
    </Row>
  </Container>
);

let dataState = {};
export const CloudAccountTable = ({
  editAWS,
  setEditAWS,
  setEditAZURE,
  editAZURE,
  editGCP,
  setEditGCP,
  uniqueFormData,
  setUniqueFormData,
  tableData,
  onEdit,
  refreshTableData,
  setTableData,
  loading,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  // const [data, setData] = useState([]);
  const [rowToDelete, setRowToDelete] = useState(null);

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  const [modalShow, setModalShow] = useState(false);

  const handleEdit = (user) => {
    if (user.cloudtypename === "AWS") {
      setEditAWS(true);
      setUniqueFormData(user);
    } else if (user.cloudtypename === "Azure") {
      setEditAZURE(true);
    } else {
      setEditGCP(true);
    }
    // dataState = data;
  };

  const handleDeleteTable = (user) => {
    console.log(user.cloudaccountid);
    setRowToDelete(user);
    setModalShow(true);
  };

  const handleConfirmDelete = async () => {
    const cloudAccountId = rowToDelete.cloudaccountid;
    const url =
      "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/cloudaccounts";
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cloudaccountid: cloudAccountId }),
      });
      if (response.ok) {
        setTableData(
          tableData.filter(
            (account) => account.cloudaccountid !== cloudAccountId
          )
        );
        setModalShow(false);
      } else {
        console.error("Failed to delete record:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", { month: "short" });
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  return (
    <div className="container">
      <div className="top_section">
        {loading ? (
          <div
            style={{
              position: "relative",
              top: "230px",
              left: "43%",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : editAWS ? (
          ""
        ) : (
          <div className="top-panel-cloudAccount">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle variant="success" className="dropdown_csp_field">
                <span>Choose Service Provider</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown_menu_csp">
                <Dropdown.Item className="dropdown_item_csp" eventKey="AWS">
                  AWS
                </Dropdown.Item>
                <Dropdown.Item className="dropdown_item_csp" eventKey="GCP">
                  GCP
                </Dropdown.Item>
                <Dropdown.Item className="dropdown_item_csp" eventKey="Azure">
                  Azure
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="search-bar-cloudAccount">
              <img
                src={searchIcon}
                alt="Seach Icon"
                className="search-icon-cloudAccount"
              />
              <input
                type="text"
                placeholder="Search Cloud Account"
                className="search-input-cloudAccount"
                // value={searchTerm}
                // onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button
                className="search-button-cloudAccount"
                // onClick={handleSearch}
              >
                Search
              </Button>
              <span
                className="reset-text-cloudAccount"
                // onClick={onReset}
              >
                Reset
              </span>
            </div>
          </div>
        )}
      </div>
      {editAWS ? (
        ""
      ) : (
        <Table className="custom-table-cloud-account">
          <thead>
            <tr className="cloud_account_table_tr">
              <th className="cloud_account_table_th">Sl No</th>
              <th className="cloud_account_table_th">Cloud Service Provider</th>
              <th className="cloud_account_table_th">
                Cloud ID
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Popover
                      style={{
                        padding: "9px",
                        fontFamily: "Noto Sans",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "19.07px",
                      }}
                    >
                      Account ID/Subscription ID/ <br />
                      Project ID
                    </Popover>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <img
                      src={tooltip}
                      alt=""
                      ref={ref}
                      roundedCircle
                      {...triggerHandler}
                      className="d-inline-flex align-items-center mx-1"
                    />
                  )}
                </OverlayTrigger>
              </th>
              <th className="cloud_account_table_th">Created Date</th>
              <th className="cloud_account_table_th">Business Unit</th>
              <th className="cloud_account_table_th">
                Account Owner's Email ID
              </th>
              <th className="cloud_account_table_th">Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* {Array.isArray(tableData)
              ? tableData
                  .slice()
                  .sort((a, b) => a.cloudaccountid - b.cloudaccountid)
                  .map((user) => (
                    <tr key={user.cloudaccountid}>
                      <td className="cloud_account_td">
                        {user.cloudaccountid}
                      </td>
                      <td className="cloud_account_td">{user.cloudtypename}</td>
                      <td className="cloud_account_td">{user.accountid}</td>
                      <td className="cloud_account_td">
                        {formatDate(user.createdon)} (UTC)
                      </td>
                      <td className="cloud_account_td">{user.businessname}</td>
                      <td className="cloud_account_td">
                        {user.accountowneremailid}
                      </td>

                      <td className="cloud_account_td">
                        <img
                          src={Edit}
                          alt="Edit"
                          className="action-edit mx-2"
                          onClick={() => handleEdit(user, user.cloudaccountid)}
                          style={{ cursor: "pointer" }}
                        />
                        <img
                          src={Delete}
                          alt="Delete"
                          className="action-delete"
                          onClick={() => handleDeleteTable(user)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))
              : ""} */}
            {Array.isArray(tableData)
              ? tableData
                  .slice()
                  .sort((a, b) => a.cloudaccountid - b.cloudaccountid)
                  .map((user, index) => (
                    <tr key={user.cloudaccountid}>
                      <td className="cloud_account_td">{index + 1}</td>{" "}
                      {/* Serial Number */}
                      <td className="cloud_account_td">{user.cloudtypename}</td>
                      <td className="cloud_account_td">{user.accountid}</td>
                      <td className="cloud_account_td">
                        {formatDate(user.createdon)} (UTC)
                      </td>
                      <td className="cloud_account_td">{user.businessname}</td>
                      <td className="cloud_account_td">
                        {user.accountowneremailid}
                      </td>
                      <td className="cloud_account_td">
                        <img
                          src={Edit}
                          alt="Edit"
                          className="action-edit mx-2"
                          onClick={() => handleEdit(user, user.cloudaccountid)}
                          style={{ cursor: "pointer" }}
                        />
                        <img
                          src={Delete}
                          alt="Delete"
                          className="action-delete"
                          onClick={() => handleDeleteTable(user)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))
              : ""}
          </tbody>

          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            centered
            className="custom-modal-delete"
          >
            <Modal.Header>
              <Modal.Title style={{ flex: 1 }}>Delete User</Modal.Title>
              <img
                src={cancel}
                alt="Close"
                onClick={() => setModalShow(false)}
              />
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete cloud Account?
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="confirm-btn-delete"
                onClick={handleConfirmDelete}
              >
                Confirm
              </Button>
              <Button
                className="cancel-btn-delete"
                onClick={() => setModalShow(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </Table>
      )}
    </div>
  );
};
