import React, { useEffect, useMemo, useState } from "react";
import "../styles/riskAssessment.css";
import {
  Dropdown,
  Table,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";
import "../styles/riskAssessmentTable.css";
import polygonClose from "../Icons/polygonClose.svg";
import polygonOpen from "../Icons/polygonOpen.svg";
import greenCircle from "../Icons/roundCircleGreen.svg";
import redCircle from "../Icons/roundCircleRed.svg";
import orangeCircle from "../Icons/roundCircleOrange.svg";
import blueCircle from "../Icons/roundCircleBlue.svg";
import downloadIcon from "../Icons/downloadIcon.svg";
import submitIcon from "../Icons/submitIcon.svg";
import tooltip from "../Icons/tooltip.svg";
import axios from "axios";

export const RiskAssessment = ({
  filtersRisk,
  handleResetRiskAssessment,
  handleRiskAssessmentFilterChange,
  isDropdownEnabled,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [regulatoryStandard, setRegulatoryStandard] = useState([]);

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  useEffect(() => {
    const fetchRegulatoryStandard = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/controlmappings/regulatory_standard"
        );
        if (response.status === 200) {
          const items = response.data.body.Items.filter(
            (item) => item.status === "True" // Compare status as "True"
          ).map((item) => ({
            id: item.regulatory_standard_id,
            name: item.regulatory_standard_name.replace(/_/g, " "),
          }));
          console.log("response Data", response.data.body);
          setRegulatoryStandard(items);
        }
      } catch (error) {
        console.error("Error fetching Regulatory Types:", error);
      }
    };
    fetchRegulatoryStandard();
  }, []);

  return (
    <>
      <div className="topHeading mb-3 mx-1">Risk Assessment</div>
      <div className="topSection mx-1">
        <label className="regStandText">Regulatory Standard</label>
        {/* Regulatory Standard DropDown */}
        <Dropdown
          onSelect={(eventKey) =>
            handleRiskAssessmentFilterChange("regulatoryStandard", eventKey)
          }
        >
          <Dropdown.Toggle className="regulatoryStandardDropDown1 mx-3">
            <span>
              {filtersRisk.regulatoryStandard || "Choose regulatory Std"}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdownMenuRegulatoryStandard1">
            {regulatoryStandard
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((standard) => (
                <Dropdown.Item
                  key={standard.id}
                  className="dropdown_item_cloud_service"
                  eventKey={standard.name}
                >
                  {standard.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        <label className="controlTypeText ">Control Type</label>
        {/* Control Type DropDown */}
        <Dropdown
          onSelect={(eventKey) =>
            handleRiskAssessmentFilterChange("controlCategory", eventKey)
          }
        >
          <Dropdown.Toggle
            variant="secondary"
            className="regulatoryStandardDropDown2 mx-2"
            disabled={!isDropdownEnabled}
          >
            <span>{filtersRisk.controlCategory || "Choose Control Type"}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdownMenuRegulatoryStandard2">
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard2"
              eventKey="Technical"
            >
              Technical
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard2"
              eventKey="  Non-Technical"
            >
              Non-Technical
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard2"
              eventKey="Technical+Non-Technical Control"
            >
              Tech+Non-Tech
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <label className="complianceText mx-2">Severity</label>
        {/* Severity DropDown */}
        <Dropdown
          onSelect={(eventKey) =>
            handleRiskAssessmentFilterChange("severity", eventKey)
          }
        >
          <Dropdown.Toggle
            variant="secondary"
            className="regulatoryStandardDropDown3 mx-2"
            disabled={!isDropdownEnabled}
          >
            <span>{filtersRisk.severity || "Choose Severity"} </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdownMenuRegulatoryStandard3">
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard3"
              eventKey="High"
            >
              High
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard3"
              eventKey="Low"
            >
              Low
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard3"
              eventKey="Medium"
            >
              Medium
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <label className="complianceText mx-2">Compliance Status</label>
        {/* Compliance Status DropDown */}
        <Dropdown
          onSelect={(eventKey) =>
            handleRiskAssessmentFilterChange("complianceRisk", eventKey)
          }
        >
          <Dropdown.Toggle
            variant="secondary"
            className="regulatoryStandardDropDown3 mx-1"
            disabled={!isDropdownEnabled}
          >
            <span>{filtersRisk.complianceRisk || "Compliance Status"}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdownMenuRegulatoryStandard3">
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard3"
              eventKey="High"
            >
              High
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard3"
              eventKey="Medium"
            >
              Medium
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdownItemRegulatoryStandard3"
              eventKey="Low"
            >
              Low
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Row>
        <Col className="mt-3 mx-1 topSection" md={8}>
          <label className="regStandText">Technical Status</label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* Technical Status DropDown */}
          <Dropdown
            onSelect={(eventKey) =>
              handleRiskAssessmentFilterChange("technicalRisk", eventKey)
            }
          >
            <Dropdown.Toggle
              variant="secondary"
              className="regulatoryStandardDropDown1 mx-4"
              disabled={!isDropdownEnabled}
            >
              <span>
                {filtersRisk.technicalRisk || "Choose Technical Status"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdownMenuRegulatoryStandard1">
              <Dropdown.Item
                className="dropdownItemRegulatoryStandard1"
                eventKey="High"
              >
                High
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdownItemRegulatoryStandard1"
                eventKey="Medium"
              >
                Medium
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdownItemRegulatoryStandard1"
                eventKey="Low"
              >
                Low
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span className="resetBtn" onClick={handleResetRiskAssessment}>
            Reset
          </span>
        </Col>
        <Col className="mt-4 topSection">
          <button className="policyControlBtn">
            <span className="policyControlText">Policy Control Count</span>
            <span className="policyCount">316</span>
          </button>
          &nbsp; &nbsp;
          <button className="mx-3 riskRegisterBtn">
            <span className="riskRegisterText">Risk Register</span>
          </button>
        </Col>
      </Row>
    </>
  );
};

export const RiskAssessmentTable = ({ searchTerm, filtersRisk }) => {
  const [openRow, setOpenRow] = useState(null);
  const [showColumns, setShowColumns] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://xok09dvudg.execute-api.ap-south-1.amazonaws.com/Dev/risk_assessment"
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data.body);
          setMainData(data.riskAssessmentInformation);
        } else {
          console.error("Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredData = useMemo(() => {
    return mainData
      .map((item) => ({
        ...item,
        subDomainList: item.subDomainList.filter(
          (subItem) =>
            (!filtersRisk.severity ||
              subItem.severity === filtersRisk.severity) &&
            (!filtersRisk.controlCategory ||
              subItem.controlCategory === filtersRisk.controlCategory)
        ),
      }))
      .filter(
        (item) =>
          (!filtersRisk.name ||
            item.arcFrameworkDomainName
              .toLowerCase()
              .includes(filtersRisk.name.toLowerCase())) &&
          (!filtersRisk.complianceRisk ||
            item.complianceRiskRating === filtersRisk.complianceRisk) &&
          (!filtersRisk.technicalRisk ||
            item.technicalRiskRating === filtersRisk.technicalRisk) &&
          (!searchTerm ||
            item.arcFrameworkDomainName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
      );
  }, [mainData, filtersRisk, searchTerm]);

  const toggleDropdown = (arcFrameWork) => {
    const isOpen = openRow === arcFrameWork;
    setOpenRow(isOpen ? null : arcFrameWork);
    setShowColumns(!isOpen);
  };

  const getComplianceRiskImage = (complianceRisk) => {
    switch (complianceRisk) {
      case "High":
        return redCircle;
      case "Medium":
        return orangeCircle;
      case "Low":
        return greenCircle;
      default:
        return null;
    }
  };

  const getTechnicalRiskImage = (technicalRisk) => {
    switch (technicalRisk) {
      case "High":
        return redCircle;
      case "Medium":
        return orangeCircle;
      case "Low":
        return greenCircle;
      default:
        return null;
    }
  };

  const getSeverityImage = (severity) => {
    switch (severity) {
      case "High":
        return redCircle;
      case "Medium":
        return orangeCircle;
      case "Low":
        return greenCircle;
      default:
        return null;
    }
  };

  const handleDownloadData = () => {
    setModalVisible(true);
  };
  const handleOk = () => {
    setModalVisible(false);
  };

  return (
    <div style={{ maxHeight: "500px", overflow: "auto", maxWidth: "100%" }}>
      <Table
        className="custom-table-risk"
        style={{ minWidth: "1200px", width: "100%" }}
      >
        <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
          <tr>
            <th className="table_heading">ARC Framework Controls</th>
            {showColumns && <th className="table_heading">Severity</th>}
            {showColumns && <th className="table_heading">Control Category</th>}
            <th className="table_heading">Compliance Risk Rating</th>
            <th className="table_heading">Technical Risk Rating</th>
            {showColumns && <th className="table_heading">Evidence</th>}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "570px",
                left: "55%",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            filteredData.map((item) => (
              <React.Fragment key={item.arctId}>
                <tr className="data-row">
                  <td className="td_items mx-3">
                    {openRow === item.arcFrameworkDomainName ? (
                      <div>
                        <img
                          src={polygonOpen}
                          alt=""
                          onClick={() =>
                            toggleDropdown(item.arcFrameworkDomainName)
                          }
                        />
                        <span className="mx-2">
                          {item.arcFrameworkDomainName}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={polygonClose}
                          alt=""
                          onClick={() =>
                            toggleDropdown(item.arcFrameworkDomainName)
                          }
                        />
                        <span className="mx-2">
                          {item.arcFrameworkDomainName}
                        </span>
                      </div>
                    )}
                  </td>
                  {showColumns && <td className="td_items"></td>}
                  {showColumns && <td className="td_items"></td>}
                  <td className="td_items">
                    {/* Compliance Risk Rating, parent data */}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover
                          style={{
                            padding: "5px",
                            fontFamily: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "19.07px",
                          }}
                        >
                          {item.complianceRiskRating}
                        </Popover>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <img
                          src={getComplianceRiskImage(
                            item.complianceRiskRating
                          )}
                          alt={item.complianceRisk}
                          ref={ref}
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        />
                      )}
                    </OverlayTrigger>
                  </td>
                  <td className="td_items">
                    {/* Technical Risk Rating, parent data */}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover
                          style={{
                            padding: "5px",
                            fontFamily: "Noto Sans",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "19.07px",
                          }}
                        >
                          {item.technicalRiskRating}
                        </Popover>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <img
                          src={getTechnicalRiskImage(item.technicalRiskRating)}
                          alt={item.technicalRisk}
                          ref={ref}
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                        />
                      )}
                    </OverlayTrigger>
                  </td>
                </tr>
                {openRow === item.arcFrameworkDomainName &&
                  item.subDomainList.map((subItem, index) => (
                    <tr key={index} className="sub-data-row">
                      <td className="td_items" style={{ border: "none" }}>
                        <ul
                          style={{
                            listStyleType: "none",
                            paddingLeft: "17px",
                            margin: 0,
                          }}
                        >
                          <li
                            style={{
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img src={blueCircle} alt="" />
                            <span
                              className="mx-2"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {subItem.arcFrameWorkSubDomainName}
                            </span>
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Popover className="tooltip_risk">
                                  {subItem.controlObjective}
                                </Popover>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <img
                                  src={tooltip}
                                  alt=""
                                  ref={ref}
                                  roundedCircle
                                  {...triggerHandler}
                                  className="d-inline-flex align-items-center"
                                />
                              )}
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </td>
                      <td className="td_items">
                        {/* Severity Subdata */}
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Popover
                              style={{
                                padding: "5px",
                                fontFamily: "Noto Sans",
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "19.07px",
                              }}
                            >
                              {subItem.severity}
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <img
                              src={getSeverityImage(subItem.severity)}
                              alt={item.complianceRisk}
                              ref={ref}
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                            />
                          )}
                        </OverlayTrigger>
                      </td>
                      <td className="td_items">
                        {/* Control Category subData */}
                        {subItem.controlCategory}
                      </td>
                      <td className="td_items">
                        {/* Compliance risk rating sub data */}
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Popover
                              style={{
                                padding: "5px",
                                fontFamily: "Noto Sans",
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "19.07px",
                              }}
                            >
                              {subItem.complianceRiskRating}
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <img
                              src={getComplianceRiskImage(
                                subItem.complianceRiskRating
                              )}
                              alt={item.complianceRisk}
                              ref={ref}
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                            />
                          )}
                        </OverlayTrigger>
                      </td>
                      <td className="td_items">
                        {/* Technical risk rating sub data */}
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Popover
                              style={{
                                padding: "5px",
                                fontFamily: "Noto Sans",
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "19.07px",
                              }}
                            >
                              {subItem.technicalRiskRating}
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <img
                              src={getTechnicalRiskImage(
                                subItem.technicalRiskRating
                              )}
                              alt={subItem.technicalRisk}
                              ref={ref}
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                            />
                          )}
                        </OverlayTrigger>
                      </td>
                      <td className="td_items">
                        {/* Evidence dropDown in sub data */}
                        <Dropdown onSelect={handleSelect}>
                          <Dropdown.Toggle
                            variant=""
                            className="evidenceDropDown"
                          >
                            <span
                              style={{
                                fontFamily: "Noto Sans",
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "16.34px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#898989",
                              }}
                            >
                              Evidence List
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="evidenceDropDownMenu">
                            <Dropdown.Item
                              className="evidenceDropDownItem"
                              eventKey="AWS"
                              href="/ccsapp.pdf"
                              download="ccsapp.pdf"
                            >
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                ccs.pdf
                                <img
                                  src={downloadIcon}
                                  alt=""
                                  onClick={handleDownloadData}
                                />
                              </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="evidenceDropDownItem"
                              eventKey="GCP"
                            >
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                ccs.pdf
                                <img
                                  src={downloadIcon}
                                  alt=""
                                  onClick={handleDownloadData}
                                />
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Modal
                          show={modalVisible}
                          centered
                          className="modal-createbu"
                        >
                          <Modal.Body className="createbu-dialog-body">
                            <div className="submit-icon">
                              <img src={submitIcon} alt="Ok" />
                            </div>
                            <h3 className="user-createbu-text">
                              Download Successful
                            </h3>
                            <p className="success-createbu-content">
                              The file”abc.png” has been successfully downloaded
                            </p>
                          </Modal.Body>
                          <Modal.Footer className="createbu-model-footer">
                            <Button className="ok_button" onClick={handleOk}>
                              Ok
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};
