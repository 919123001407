import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/leftNavigation.css";
import WiproLogo from "../Images/WiproLogo.svg";
import inventory from "../Icons/inventory.svg";
import homeIcon from "../Icons/homeIcon.svg";
import homeWhite from "../Icons/homeWhite.svg";
import regulatory from "../Icons/regulatoryIcon.svg";
import regulatoryWhite from "../Icons/regulatoryWhite.svg";
import admin from "../Icons/adminIcon.svg";
import adminWhite from "../Icons/adminWhite.svg";
import inventoryWhite from "../Icons/inventoryWhite.svg";
import alertWhite from "../Icons/alertWhite.svg";
import alert from "../Icons/alert.svg";
import riskAssessment from "../Icons/riskAssessment.svg";
import riskAssessWhite from "../Icons/riskAssessWhite.svg";
import dashboard from "../Icons/dashboard.svg";
import dashboardWhite from "../Icons/dashboardWhite.svg";

import setting from "../Icons/settingIcon.svg";
import settingsWhite from "../Icons/settingsWhite.svg";
import signout from "../Icons/signoutIcon.svg";
import { Col, Container, Nav, Row } from "react-bootstrap";
// import { useAuth } from "../Router/authContext";

const LeftNavigation = ({ onMenuClick, menuItemSelect }) => {
  const navigate = useNavigate();
  // const { logout } = useAuth();

  const handleSignoutBtn = (e) => {
    e.preventDefault();
    navigate("/");
    localStorage.removeItem("menuItemSelect");
  };

  // const handleSignoutBtn = (e) => {
  //   e.preventDefault();
  //   logout(); // Clear the authentication state
  //   navigate("/");
  //   localStorage.removeItem("menuItemSelect");
  // };

  return (
    <Container
      fluid
      className="left-sidebar d-flex flex-column vh-100"
      style={{ position: "fixed", width: "20%" }}
    >
      <Row>
        <Col>
          {/* Logo Section */}
          <div className="logo ">
            <img
              src={WiproLogo}
              alt="wipro-logo"
              className=" text-center "
              style={{ height: "79.07px", width: "79.07px" }}
            />
            <span className="logo_text">Cloud Compliance Shield</span>
          </div>
          <span className="main_menu">Main Menu</span>
          <Nav className="flex-column flex-grow-1">
            <Nav.Link
              href="#"
              className="d-flex align-items-center "
              style={{
                backgroundColor: menuItemSelect === "Home" ? "#2787e4" : "",
                color: menuItemSelect === "Home" ? "white" : "",
                fontWeight: menuItemSelect === "Home" ? "bold" : "",
              }}
              onClick={() => onMenuClick("Home")}
            >
              {menuItemSelect === "Home" ? (
                <img src={homeWhite} alt="" className="icons" />
              ) : (
                <img src={homeIcon} alt="" className="setting_icons" />
              )}
              <span className="setting_text"> Home </span>
            </Nav.Link>
            <Nav.Link
              href="#"
              className="d-flex align-items-center "
              style={{
                backgroundColor:
                  menuItemSelect === "Regulatory Standards" ? "#2787e4" : "",
                color: menuItemSelect === "Regulatory Standards" ? "white" : "",
                fontWeight:
                  menuItemSelect === "Regulatory Standards" ? "bold" : "",
              }}
              onClick={() => onMenuClick("Regulatory Standards")}
            >
              {menuItemSelect === "Regulatory Standards" ? (
                <img src={regulatoryWhite} alt="" className="icons" />
              ) : (
                <img src={regulatory} alt="" className="setting_icons" />
              )}
              <span className="setting_text"> Regulatory Standards </span>
            </Nav.Link>
            <Nav.Link
              className="d-flex align-items-center "
              style={{
                backgroundColor:
                  menuItemSelect === "Admin Configuration" ? "#2787e4" : "",
                color: menuItemSelect === "Admin Configuration" ? "white" : "",
                fontWeight:
                  menuItemSelect === "Admin Configuration" ? "bold" : "",
              }}
              onClick={() => onMenuClick("Admin Configuration")}
            >
              {menuItemSelect === "Admin Configuration" ? (
                <img src={adminWhite} alt="" className="icons" />
              ) : (
                <img src={admin} alt="" className="setting_icons" />
              )}
              <span className="setting_text"> Admin Configuration </span>
            </Nav.Link>
            <Nav.Link
              href="#"
              className="d-flex align-items-center "
              style={{
                backgroundColor:
                  menuItemSelect === "Inventory" ? "#2787e4" : "",
                color: menuItemSelect === "Inventory" ? "white" : "",
                fontWeight: menuItemSelect === "Inventory" ? "bold" : "",
              }}
              onClick={() => onMenuClick("Inventory")}
            >
              {menuItemSelect === "Inventory" ? (
                <img src={inventoryWhite} alt="" className="icons" />
              ) : (
                <img src={inventory} alt="" className="setting_icons" />
              )}
              <span className="setting_text"> Inventory </span>
            </Nav.Link>
            <Nav.Link
              href="#"
              className="d-flex align-items-center "
              style={{
                backgroundColor: menuItemSelect === "Alerts" ? "#2787e4" : "",
                color: menuItemSelect === "Alerts" ? "white" : "",
                fontWeight: menuItemSelect === "Alerts" ? "bold" : "",
              }}
              onClick={() => onMenuClick("Alerts")}
            >
              {menuItemSelect === "Alerts" ? (
                <img src={alertWhite} alt="" className="icons" />
              ) : (
                <img src={alert} alt="" className="setting_icons" />
              )}
              <span className="setting_text"> Alerts </span>
            </Nav.Link>
            <Nav.Link
              href="#"
              className="d-flex align-items-center "
              style={{
                backgroundColor:
                  menuItemSelect === "Risk Assessment" ? "#2787e4" : "",
                color: menuItemSelect === "Risk Assessment" ? "white" : "",
                fontWeight: menuItemSelect === "Risk Assessment" ? "bold" : "",
              }}
              onClick={() => onMenuClick("Risk Assessment")}
            >
              {menuItemSelect === "Risk Assessment" ? (
                <img src={riskAssessWhite} alt="" className="icons" />
              ) : (
                <img src={riskAssessment} alt="" className="setting_icons" />
              )}
              <span className="setting_text"> Risk Assessment </span>
            </Nav.Link>
            <Nav.Link
              href="#"
              className="d-flex align-items-center "
              style={{
                backgroundColor:
                  menuItemSelect === "dashboardView" ? "#2787e4" : "",
                color: menuItemSelect === "dashboardView" ? "white" : "",
                fontWeight: menuItemSelect === "dashboardView" ? "bold" : "",
              }}
              onClick={() => onMenuClick("dashboardView")}
            >
              {menuItemSelect === "dashboardView" ? (
                <img src={dashboardWhite} alt="" className="setting_icons" />
              ) : (
                <img src={dashboard} alt="" className="setting_icons" />
              )}
              <span className="setting_text"> Dashboard </span>
            </Nav.Link>
            <span className="preferences">Preferences</span>
            <br />
            <Nav.Link
              href="#"
              className="d-flex align-items-center "
              style={{
                backgroundColor: menuItemSelect === "Settings" ? "#2787e4" : "",
                color: menuItemSelect === "Settings" ? "white" : "",
                fontWeight: menuItemSelect === "Settings" ? "bold" : "",
              }}
              onClick={() => onMenuClick("Settings")}
            >
              {menuItemSelect === "Settings" ? (
                <img src={settingsWhite} alt="" className="setting_icons" />
              ) : (
                <img src={setting} alt="" className="setting_icons" />
              )}
              <span className="setting_text"> Settings </span>
            </Nav.Link>
          </Nav>
          <Nav
            style={{
              display: "flex",
              marginTop: "35%",
              flexDirection: "column",
            }}
          >
            <Nav.Link
              href="#"
              className=" d-flex align-items-center itemNav"
              onClick={handleSignoutBtn}
            >
              <img src={signout} alt="" className="signout_icons" />
              <span className="signout_text"> Sign Out </span>
            </Nav.Link>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default LeftNavigation;
