import React, { useState } from "react";
import "../styles/userManagement.css";
import {
  Container,
  Button,
  Table,
  Form,
  Modal,
  Dropdown,
} from "react-bootstrap";
import Edit from "../Icons/edit.svg";
import Delete from "../Icons/delete.svg";
import searchIcon from "../Icons/searchIcon.svg";
import cancel from "../Icons/cancel.svg";
import rejectIcon from "../Icons/reject.svg";
import submitIcon from "../Icons/submitIcon.svg";

const UserManagement = () => {
  const [isReviewing, setIsReviewing] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showRejectMsg, setShowRejectMsg] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Choose Role");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Choose User Status");

  const users = [
    {
      userId: 1234,
      name: "Mahesh Ponugoti",
      email: "mahesh.muniswamy@wipro.com",
      role: "Admin",
      status: "InActive",
    },
    // {
    //   userId: 1235,
    //   name: "Deepak Bal",
    //   email: "sakshi.samdani@wipro.com",
    //   role: "Admin",
    //   status: "InActive",
    // },
    // {
    //   userId: 1236,
    //   name: "Pragati Tiwari",
    //   email: "pragati.tiwari@wipro.com",
    //   role: "Admin",
    //   status: "Active",
    // },
    {
      userId: 1237,
      name: "Utkarsh Yadav",
      email: "utkarsh.yadav@wipro.com",
      role: "Admin",
      status: "Active",
    },
    // {
    //   userId: 1238,
    //   name: "Mahesh Ponugoti",
    //   email: "mahesh.muniswamy@wipro.com",
    //   role: "Admin",
    //   status: "Active",
    // },
    {
      userId: 1239,
      name: "Sakshi Samdani",
      email: "sakshi.samdani@wipro.com",
      role: "Admin",
      status: "New Request",
    },
  ];

  const getStatusClass = (status) => {
    if (status === "Active") return "status-button status-active";
    if (status === "InActive") return "status-button status-inactive";
    if (status === "New Request") return "status-button status-new-request";
    return ""; // Default case
  };

  const handleReview = (user) => {
    setSelectedUser(user);
    setIsReviewing(true);
  };
  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsEdit(true);
  };

  const handleReject = (e) => {
    e.preventDefault();
    setShowRejectDialog(true);
  };
  const handleApprove = (e) => {
    e.preventDefault();
    setShowApproveDialog(true);
  };
  const handleCloseDialog = () => {
    setShowRejectDialog(false);
  };

  const handleRejectMsg = () => {
    setShowRejectDialog(false);
    setShowRejectMsg(true);
  };
  const handleRejected = () => {
    setShowRejectMsg(false);
    setIsReviewing(false);
  };
  const handleApproved = () => {
    setShowApproveDialog(false);
    setIsReviewing(false);
  };
  const handleCancel = () => {
    setIsEdit(false);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    setShowUpdateDialog(true);
  };
  const handleUpdated = () => {
    setShowUpdateDialog(false);
    setIsEdit(false);
  };
  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };
  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };
  const handleFilterSelect = (eventKey) => {
    setSelectedStatus(eventKey);
  };
  const filteredUsers =
    selectedStatus === "Choose User Status"
      ? users
      : users.filter((user) => user.status === selectedStatus);

  return (
    <Container>
      {!isReviewing && !isEdit ? (
        <div className="container">
          <div className="filter-container">
            <Dropdown onSelect={handleFilterSelect}>
              <Dropdown.Toggle
                variant="success"
                className="dropdown-userStatus-field"
              >
                <span>{selectedStatus}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-userStatus-menu">
                <Dropdown.Item
                  eventKey="Choose User Status"
                  className="dropdown-userStatus-item"
                >
                  Choose User Status
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-userStatus-item"
                  eventKey="Active"
                >
                  Active
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-userStatus-item"
                  eventKey="InActive"
                >
                  InActive
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-userStatus-item"
                  eventKey="New Request"
                >
                  New Request
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <img src={searchIcon} alt="Seach Icon" className="search-icon" />
            <input
              type="text"
              placeholder="Search User Name/E-mail Address"
              className="search-input"
            />
            <span className="reset-text">Reset</span>
            <Button className="search-button">Search</Button>
          </div>
          <Table className="custom-table">
            <thead>
              <tr>
                <th style={{ paddingBottom: "17px" }}>Name</th>
                <th style={{ paddingBottom: "17px" }}>E-mail ID</th>
                <th style={{ paddingBottom: "17px" }}>Role</th>
                <th style={{ paddingBottom: "17px" }}>User Status</th>
                <th style={{ paddingBottom: "17px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.userId}>
                  {/* <td>{user.userId}</td> */}
                  <td style={{ paddingTop: "15px" }}>{user.name}</td>
                  <td style={{ paddingTop: "15px" }}>{user.email}</td>
                  <td style={{ paddingTop: "15px" }}>{user.role}</td>
                  <td>
                    <span className={getStatusClass(user.status)}>
                      {user.status}
                    </span>
                  </td>
                  <td>
                    {user.status === "New Request" ? (
                      <Button
                        className="review-button"
                        onClick={() => handleReview(user)}
                      >
                        Review
                      </Button>
                    ) : (
                      <>
                        <img
                          src={Edit}
                          alt="Edit"
                          className="action-edit"
                          onClick={() => handleEdit(user)}
                        />
                        <img
                          src={Delete}
                          alt="Delete"
                          className="action-delete"
                          onClick={() => setShowDeleteDialog(true)}
                        />
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal
            show={showDeleteDialog}
            onHide={() => setShowDeleteDialog(false)}
            centered
            className="custom-modal"
          >
            <Modal.Header>
              <Modal.Title style={{ flex: 1 }}>Delete User</Modal.Title>
              <img
                src={cancel}
                alt="Close"
                onClick={() => setShowDeleteDialog(false)}
              />
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete ”sample text” user?
            </Modal.Body>
            <Modal.Footer>
              <button
                className="confirm-btn"
                onClick={() => setShowDeleteDialog(false)}
              >
                Confirm
              </button>
              <Button
                className="cancel-btn"
                onClick={() => setShowDeleteDialog(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : isReviewing ? (
        <div className="container">
          <div className="review-title">User Details Review</div>
          <div className="review-box">
            <div className="review-field">
              <label htmlFor="name">Organization Email ID</label>
              <input type="text" value={selectedUser.email} disabled />
            </div>
            <div className="review-field">
              <label htmlFor="email">Requested Date</label>
              <input type="text" value={selectedUser.email} disabled />
            </div>
            <div className="review-field">
              <label htmlFor="role">Employee ID</label>
              <input type="text" value={selectedUser.userId} disabled />
            </div>
            <div className="review-field">
              <label htmlFor="status">Full Name</label>
              <input type="text" value={selectedUser.name} disabled />
            </div>
            <div className="review-field">
              <label htmlFor="status">Department Name</label>
              <input type="text" value={selectedUser.name} disabled />
            </div>
            <div className="review-field">
              <label htmlFor="status">Role</label>
              <input type="text" value={selectedUser.role} disabled />
            </div>
            <div className="review-field">
              <label htmlFor="status">Request Reason</label>
              <textarea disabled value={selectedUser.role} rows="3" />
            </div>
            <div className="review-actions">
              <Button className="approve-button" onClick={handleApprove}>
                Approve
              </Button>
              <Button className="reject-button" onClick={handleReject}>
                Reject
              </Button>
            </div>
            <Modal
              show={showRejectDialog}
              onHide={handleCloseDialog}
              centered
              className="modalStyle"
            >
              <Modal.Header className="model_header">
                <div className="header-content">
                  <div>
                    <Modal.Title>User Rejected</Modal.Title>
                    <p className="modalText">
                      Please Enter the reason for rejecting the user.
                    </p>
                  </div>
                  <div className="close-icon" onClick={handleCloseDialog}>
                    <img src={cancel} alt="Close" />
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="dialog-body">
                <Form>
                  <Form.Group>
                    <Form.Label className="rejection_text_dialog">
                      Rejection Reason
                    </Form.Label>
                    <Form.Control
                      className="rejection_field_dialog"
                      type="text"
                      name=""
                      id=""
                      placeholder="Sample text for reason of rejection"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer className="model_footer">
                <Button className="ok_btn" onClick={handleRejectMsg}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showRejectMsg} centered className="modalStyle-msg">
              <Modal.Body className="dialog-body">
                <div className="reject-icon">
                  <img src={rejectIcon} alt="Ok" />
                </div>
                <h3 className="user-rejected-text">New User Rejected</h3>
                <p className="success-rejected-content">
                  New user has been successfully rejected.
                </p>
              </Modal.Body>
              <Modal.Footer className="model_footer">
                <Button className="ok_btn" onClick={handleRejected}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showApproveDialog} centered className="modalStyle-msg">
              <Modal.Body className="dialog-body">
                <div className="submit-icon">
                  <img src={submitIcon} alt="Ok" />
                </div>
                <h3 className="user-rejected-text">New User Approved</h3>
                <p className="success-rejected-content">
                  New user has been successfully addeed.
                </p>
              </Modal.Body>
              <Modal.Footer className="model_footer">
                <Button className="ok_btn" onClick={handleApproved}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="edit-title">Edit User Management</div>
          <div
            className="edit-box"
            style={{ height: isDropdownOpen ? "513px" : "380px" }}
          >
            <div className="edit-field">
              <label htmlFor="email">
                Email ID<span style={{ color: "#FF2929" }}>*</span>
              </label>
              <input type="text" value={selectedUser.email} disabled />
            </div>
            <div className="edit-field">
              <label htmlFor="name">
                First Name <span style={{ color: "#FF2929" }}>*</span>
              </label>
              <input type="text" value={selectedUser.name} />
            </div>
            <div className="edit-field">
              <label htmlFor="name">
                Last Name <span style={{ color: "#FF2929" }}>*</span>
              </label>
              <input type="text" value={selectedUser.name} />
            </div>
            <div className="edit-field">
              <label htmlFor="name">
                Display Name <span style={{ color: "#FF2929" }}>*</span>
              </label>
              <input type="text" value={selectedUser.name} />
            </div>
            <div className="edit-field">
              <label htmlFor="role">
                Role<span style={{ color: "#FF2929" }}>*</span>
              </label>
              <Dropdown onSelect={handleSelect} onToggle={handleDropdownToggle}>
                <Dropdown.Toggle
                  variant="success"
                  className="dropdown_edit_user_role"
                >
                  <span>{selectedOption}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown_edit_user_menu_role">
                  <Dropdown.Item
                    className="dropdown_edit_user_item_role"
                    eventKey="Ciso"
                  >
                    Ciso
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Security Analysts"
                    className="dropdown_edit_user_item_role"
                  >
                    Security Analysts
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Risk Officer"
                    className="dropdown_edit_user_item_role"
                  >
                    Risk Officer
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown_edit_user_item_role"
                    eventKey="Auditor"
                  >
                    Auditor
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="edit-actions"
              style={{ marginTop: isDropdownOpen ? "160px" : null }}
            >
              <Button className="update-button" onClick={handleUpdate}>
                Update
              </Button>
              <Button className="cancel-button" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
            <Modal show={showUpdateDialog} centered className="modal-update">
              <Modal.Body className="update-dialog-body">
                <div className="submit-icon">
                  <img src={submitIcon} alt="Ok" />
                </div>
                <h3 className="user-updated-text">
                  Changes Updated Successfully
                </h3>
                <p className="success-update-content">
                  Changes in User Management has been updated successfully
                </p>
              </Modal.Body>
              <Modal.Footer className="update-model-footer">
                <Button className="ok_button" onClick={handleUpdated}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </Container>
  );
};

export default UserManagement;
