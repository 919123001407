import React, { useContext } from "react";
import "../styles/topNavigation.css";
import notification from "../Icons/notification.svg"
import profile from "../Icons/profileImg.svg"
import roleImg from "../Icons/roleImg.svg"
import { UserContext } from '../Redux/userContext';
// import topNavIcon from "../Icons/topNavIcon.svg";
// import topNavIconUtkarsh from "../Icons/utkarsh.svg";

const TopNavigation = () => {

  const { email } = useContext(UserContext);

  const formatEmail = (email) => {
    if (!email) return { formattedName: '', initials: '' }; // Return empty strings if email is undefined

    // Split the email at the first occurrence of "@" or a number
    const [namePart] = email.split(/[@\d]/);
    const [firstName, lastName] = namePart.split('.');
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const formattedName = `${capitalize(firstName)} ${capitalize(lastName)}`;

    // Extract initials
    const initials = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

    return { formattedName, initials };
  };
  const { formattedName, initials } = formatEmail(email);

  // const emailBeforePeriod = email.split('.')[0];
  return (
    <div className="top_Navbar">
      <div className="right-content">
        <img className="test_img" src={notification} alt="Notification" />
        {/* <div className="profile-circle">
          <span>{initials}</span>
        </div> */}
        <img className="test_img" src={profile} alt="Profile" />
        <div className="email-role">
          <p>Utkarsh</p>
          {/* <p>{formattedName}</p> */}
          <img className="role_img" src={roleImg} alt="Role" />
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
